@import "../../base.scss";

.property-component-container {
    width:100vw;
    .overlay-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .wrapper{
        margin-top:5%;
        .text-info{
          font-size: 18px;
          display: none;
          &.selected{
            display: block;
          }
        }
      }
      p {
        font-family: $font-family-light;
        max-width: 90%;
        font-size: 18px;
      }
      .flex-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        h1 {
          font-family: $font-family-light;
          font-size: 24px;
        }
        .arrow-container {
          display: flex;
          align-items: center;
          width: 150px;
          opacity: 0;
          .line {
            height: 1px;
            background-color: #E5214E;
            width: 80%;
            margin-right: -5px;
          }
          .arrow-right {
            width: 24px;
            height: 24px;
            background-image: url("../../assets/icons/arrow-right.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
  
        &.selected {
          h1 {
            font-family: $font-family-bold;
            font-size: 23px;
          }
          .arrow-container {
            opacity: 1;
          }
        }
      }
    }
  
  }
  
  @media only screen and (max-width: 1750px) {
    .property-component-container {
      .overlay-container {
        p {
          font-size: 16px;
        }
        .flex-container {
          h1 {
            font-size: 19px;
          }
          &.selected {
            h1 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 1450px) {
    .property-component-container {
      .overlay-container {
        p {
          font-size: 14px;
        }
        .flex-container {
          h1 {
            font-size: 18px;
          }
          &.selected {
            h1 {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 900px) {
    .property-component-container {
      .container.image.relative {
        background-image: none;
      }
      .container-inner{
        padding: 0 10px;
        background: none;
      }
      .property-component-container {
        height: unset;
        .text-container {
          background-color: #050508;
          padding: 40px 5%;
          h3 {
            font-family: $font-family-regular;
            font-size: 22px;
            margin-bottom: 20px;
          }
          p {
            font-family:$font-family-light;
            font-size: 16px;
          }
        }
        .image {
          height: 40vh;
          background-size: cover;
          background-position: right;
          background-repeat: no-repeat;
        }
      }
      .padding {
        padding: 0 20px;
      }
    }
  }
  
  .linee-and-text{
    display: flex;
    align-items: center;
    margin-bottom:2%;
    .leftt-line{
    border: 2px solid #E5214E;
    height: 4vh;
    margin-right:3%;
    }
    .tab-title{
    font-family: $font-family-regular;
    font-size:24px;
    white-space: nowrap;
    &.selected{
      font-family: $font-family-bold;
    }
    &.smaller{
        font-size:13px;
    }
    }
    p{
    padding: 0 12px;
    }
}