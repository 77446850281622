@import "../../base.scss";

.button-container {
  padding: 10px 20px 10px 20px;
  background-color: #E5214E;
  border-radius: 5px;
  color: $white;
  font-family: $font-family-bold;
  font-size: 18px;
  display: inline-block;
  margin-top:4%;
 
  &.middle{
  margin-top: 1%;
}

  cursor: pointer;
  &.black{
    background-color: #051117;
  }
  &.mobile{
    margin-left: 15%;
    margin-bottom: 3%;
  padding: 7px 15px 7px 15px;
  background-color: #0BD8DE;
  border-radius: 5px;
  color: $white;
  font-family: $font-family-bold;
  font-size: 14px;
  display: inline-block;
  }
&.larger{
  padding:14px 50px;
  font-family: $font-family-Semibold;
}
  &.left{
    margin-left:5%;
  }
  &.border{
    border: 2px solid #0BD8DE;
    border-radius: 5px;
  }

  &.mobilesection{
    width:100%;
    text-align: center;
  }
  &.downwards{
    margin-top: 20%;
  }
  &.footer{
    width:100%;
  }
}


@media screen and (max-width: 1600px) {
.button-container{
  &.middle{
    padding: 8px 18px 8px 18px;
    margin-left:3%;
    font-size: 14px;
  }
}
}


@media screen and (max-width: 1350px) {
  .button-container{
    &.middle{
      padding: 8px 18px 8px 18px;
      margin-left:2%;
      font-size: 14px;
    }
  }
  }


@media screen and (max-width: 1200px) {
  .button-container{
    &.middle{
      padding: 8px 18px 8px 18px;
      margin-left:0%;
      font-size: 14px;
    }
  }
}