@import "../../base.scss";

.home-container{
    width:100vw;
}

.line-and-tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width:100%;
    flex-wrap:wrap;
    .linee-and-text{
        display: flex;
        align-items: center;
        margin-bottom:2%;
        .leftt-line{
        border: 2px solid #E5214E;
        background-color: #E5214E;
        height: 4vh;
        margin-left:5%;
        }
        .tab-text{
        font-family: $font-family-bold;
        width:200px;
        &.smaller{
            font-size:13px !important;
        }
        }
        p{
        padding: 0 12px;
        }
    }
}

.profile-container {
    .gap {
        gap: 40px;
    }
    .circle {
        padding: 25px;
        border-radius: 50%;
        background-color: $black;
        border: 1px solid $red;
        height: 25px;
        width: 25px;
    }
    .section-title {
        font-family: $font-family-light;
        font-size: 22px;
        text-transform: uppercase;
    }

    .main-data-wrapper {
        gap: 20px;
    }
    .option {
        display: flex;
        @extend %border-styles;

        .name {
            font-size: 20px;
            font-family: $font-family-light;
        }
        .value {
            font-size: 20px;
            font-family: $font-family-BoldItalic;
            font-family: $font-family-bold;
        }
    }
    .red-link {
        margin-left: 5%;
    }
}



.content-container {
   
    &.not-blend{
      background: #13121C;
    }
}

@media screen and (max-width: 1500px) {
    .profile-container {
        .circle {
            width: 16px;
            height: 16px;
            padding: 16px;
        }

        .section-title {
        font-size: 20px;
        }

        .main-data-wrapper {
            .option {
                .name,
                .value {
                    font-size: 16px;
                }
            }

            // .row-wrapper {
            //     // h4 {
            //     // }
            // }
        }

        // .left-container {
        //     // .title {
        //     //     margin-bottom: 15px;
        //     // }
        // }
    }
}

@media only screen and (max-width: 900px) {
    .profile-container {
        min-height: 100%;

        .lower-title {
            font-family: $font-family-light;
        }

        .content-container {
            height: unset;
        }

        .circle {
            width: 20px;
            height: 20px;
            padding: 20px;
        }

        .section-title {
        font-size: 16px;
        font-family: $font-family-light;
        }

        .main-data-wrapper {
            .option {
                flex-direction: column;
                gap: 5px;
                border-radius: 8px;
                padding: 15px 10px;

                .name,
                .value {
                    font-size: 14px;
                }
            }

            .row-wrapper {
                h4 {
                    margin-left: 0;
                    text-align: left;
                    margin-top: 10px;
                }
            }
        }

        .left-container {
            .title {
                margin-bottom: 15px;
            }
        }
    }

    .line-and-tabs {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .box-wrapper {
            padding: 10px;
            .tab-text.smaller {
                font-size: 14px;
            }
        }
    }
}
