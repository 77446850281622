@import "fonts";

// Fonts
$font-family-bold: "Montserrat-Bold";
$font-family-Semibold: "Montserrat-SemiBold";
$font-family-medium: "Montserrat-Medium";
$font-family-regular: "Montserrat-Regular";
$font-family-black: "Montserrat-Black";
$font-family-light: "Montserrat-Light";
$font-family-thin: "Montserrat-Thin";
$font-family-Xbold:"Montserrat-ExtraBold";
$font-family-italic: "Montserrat-Italic";
$font-family-SemiBoldItalic: "Montserrat-SemiBoldItalic";
$font-family-BoldItalic: "Montserrat-BoldItalic";

// Font-sizes
$fontSizeTitle: 60px;
$fontSizeSubtitle: 30px;
$fontSizeSubtitleSmall: 26px;
$fontSizeText: 22px;
$fontSizeTextSmall: 20px;

// Colors
$white: #ffffff;
$black: #04010f;
$blue: #0046f8;
$lblue: #e6edff;
$grey: #13121C;
$red: #E5214E;
$red-dark: #8d1430;
$linear-gradient: linear-gradient(#3ab2b6, #00d297);
$light-border: rgba(255, 255, 255, 0.274);

%flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

%border-styles {
  padding: 15px 20px;
  border: 1px solid $light-border;
  background-color: $black;
  border-radius: 5px;
}

@mixin scrollbar() {
  scrollbar-color: #999;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin scrollbarBlack() {
  scrollbar-color: $black;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $black;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

@mixin scrollbarRed() {
  scrollbar-color: $red;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $red;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
