@import "../../base.scss";

.mobile-container{
  display:none;
}

.dropdown {
  margin: 0 5px;
  flex-direction: column;
  @extend %flex;
  font-size: $fontSizeText;
  font-family: $font-family-regular;
  justify-content: center;
  align-items: center;
  max-width:25%;
  color:white;
  cursor: pointer;
}

.dropdown .dropbtn {
  font-size:20px;
  font-family: $font-family-regular;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0; 
  
  &.selected{
    font-family: $font-family-bold;
  white-space: nowrap;
  padding-left:5%;
  padding-right:5%;
  border-bottom: solid 3px #E5214E;
  }
}


.navbar .buttonche:hover, .dropdown:hover .dropbtn {
  border-bottom:2px solid #E5214E;
}


.dropdown-content {
  display: none;
  position:absolute;
  top: 100%;
  transform: translateY(1px);
  background-color: black;
  min-width: 350px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10000;
}


.dropdown-content .buttonche {
  font-size:16px;
  margin-left: 4%;
  width:90%;
  float: none;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  border-bottom:1px solid #E5214E;
}


.dropdown-content .buttonche:hover {
  background-color: rgb(61, 54, 54);
}


.dropdown:hover .dropdown-content {
  display: block;
}

.header-inner-element{
  font-size:20px;
  font-family: $font-family-regular;
}
.header-container {
  @extend %flex;
  justify-content: space-between;
  flex-flow:row;
  flex: 1;
  height: 110px;
  background-color: black;
  position: relative;
  .left-header-container{
    @extend %flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    width:40%;
  .header-logo {
    height: 70%;
    max-width:75%;
    flex: 1;
    margin-left:-10%;
    background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fight-Scout-logo-header.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

  .right-header-container{
    @extend %flex;
    justify-content: space-evenly;
    align-content: flex-end;
    width:60%;
    margin: 0 -5px;

  }
  .header-element {
    margin: 0 5px;
    @extend %flex;
    font-size: $fontSizeText;
    font-family: $font-family-regular;
    justify-content: center;
    align-items: center;
    max-width:25%;
    color:white;
    cursor: pointer;
    .header-inner-element {
      border-bottom: solid 4px transparent;
      &.selected {
        font-family: $font-family-bold;
        white-space: nowrap;
        padding-left:5%;
        padding-right:5%;
        border-bottom: solid 3px #E5214E;
      }
      
    }
    &.button{
      font-family: $font-family-bold;
      font-size:16px; 
      white-space: nowrap;
      padding: 0 30px;
      height:40%;
      margin-top:3%;
      border-radius: 5px;
      background-color:turquoise;
      color:white;
    }
  }
  
}
.right-header-container{
  .dropdown .dropbtn {
    font-size:20px;
    font-family: $font-family-regular;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    margin: 0; 
    
    &.selected{
      font-family: $font-family-bold;
    white-space: nowrap;
    padding-left:5%;
    padding-right:5%;
    border-bottom: solid 3px #E5214E;
    }
  }
}

.header-container{
  border-bottom:solid 1px #E5214E;
}

@media screen and (max-width: 1600px) {
  .right-header-container{
    .dropdown .dropbtn {
      font-size:17px;
      }
    }
  
  
  .header-inner-element{
    font-size:17px;
  }
  .header-element{
    &.button{
      font-size:13px;
      padding: 0 26px;
      height:40%;
    }
  }
 }
 
 @media screen and (max-width: 1200px) {
  .right-header-container{
    .dropdown .dropbtn {
      font-size:13px;
      }
    }
  
   .header-inner-element{
     font-size:13px;
   }
    .right-header-container{
    .header-element{
      &.button{
        font-size:10px;
        padding: 0 20px;
        height:30%;
        margin-top:5%;
      }
    }
  }
}
 
  @media screen and (max-width: 1000px) {
   .right-header-container{
     .header-element{
       &.button{
         font-size:10px;
         padding: 0 20px;
         height:30%;
         margin-top:6%;
       }
     }
   }
 
 }
 
 
  @media screen and (max-width: 900px) {
   .header-container{
     display:none;
   }

   .hamburger{
    background-image: url("../../assets/icons/menu-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top:10%;
    width:40px;
    height: 45px;
    margin-left: 12px;
   }
   .close-container{
    background-image: url("../../assets/icons/close-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top:5%;
    width:25px;
    height: 25px;
   }
 
   .mobile-container{
     display:flex;
     flex-flow: row;
     width:100%;
     height: 55px;
     background-color: black;
     .mobile-header-logo{
       flex:1;
       background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fight-Scout-logo-header.png");
     background-position: center;
     background-size: contain;
     background-repeat: no-repeat;
     cursor: pointer;
     width:45px;
     height:45px;
     margin-top:1%;
     margin-right: 12%;
     }
   }
 
   .header-inner-element {
     font-size: 7px;
     display:none;
   }
 
   .button{
     color:blue;
     margin-left:2px;
     cursor:pointer;
     display:flex;
   }
  
 }
 
 
 .opened-header
 {
   display:flex;
   flex-flow:column;
   justify-content: space-evenly;
   align-items: center;
   position:absolute;
   top:0;
   left:0;
   width:100vw;
   height:100vh;
   z-index:10000;
   .upper-opened-header{
     display:flex;
   flex-flow:column;
   justify-content: space-evenly;
   align-items: center;
   background-color:black;
     height:65vh;
     height: 100vh;
     width:100vw;
     .button-mobile{
       font-family: $font-family-bold;
       font-size:16px; 
       text-align: center;
       white-space: nowrap;
       padding: 10px 30px;
       width:100%;
       border-radius: 5px;
       background-color:#CB2049;
       color:white;
       cursor: pointer;
     }
     .mobile-flex-container-apps{
       margin-bottom:-5%;
       width:100%;
       display:flex;
       flex-direction: row;
       justify-content: space-evenly;
       .app-store{
         background-image:url(../../assets/icons/App-Store.svg);
         background-size:contain;
         background-repeat: no-repeat;
         width:140px;
         height:60px;
        margin-right:30px;
        cursor: pointer;
       }
       .google-play{
         background-image:url(../../assets/icons/Google-Play.svg);
         background-size:contain;
         background-repeat: no-repeat;
         width:140px;
         height:60px;
         cursor: pointer;
       }
     }
     .mobile-header-inner-element {
       display:inline;
       font-size: 21px;
       text-align: center;
       cursor:pointer;
       &.selected {
         border-bottom: solid 4px #CB2049;
         padding: 10px;
       }
     
     }
   }
   .lower-opened-header{
     height:50vh;
     width:100vw;
     backdrop-filter: blur(10px);
   }
 
   .close-opened-header{
     position:absolute;
     top:20px;
     left:20px;
     cursor:pointer;
   }
     
 }

 .mobile-header-element-and-list{
   display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top:2%;
   .mobile-list{
     margin-top: 20%;
     display: none;
     font-size: 16px;
     text-align: center;
     .list-fighter,.list-promoter,.list-manager,.list-fan,.list-coach{
       margin-bottom:15%;
       cursor: pointer;
     }
     .list-coach{
       cursor: pointer;
     }
   }
 }