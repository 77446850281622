@import "../../base.scss";

.article-text {
  display: inline-block;
  width: 90%;
  font-size: 20px;
  margin: 10px;
}

.article-container {
  width: 100vw;
  display: flex;
  flex-flow: row;
  color: white;
}

strong{
  font-family: $font-family-bold !important;
}

.left-article-container {
  .arrow-left {
    margin-left: 15px;
    background-image: url("../../assets/icons/arrow-left.svg");
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-top: 2%;
    margin-bottom: 2%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  height: 100vh;
  padding: 10px;
  width: 50%;
  display: flex;
  flex-flow: column;
  .neshto {
    overflow-y: auto;
    height: 90%;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #e5214e;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #e5214e;
  }
}
.right-article-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50%;
  height: 100vh;
  display:flex;
  align-items: flex-end;
  justify-content: center;
}
.slider{
  width:17%;
  height: 50px;
  margin-bottom:3%;
  border-radius: 7px;
  border:1px solid white;
  background-color: #1F1D2B;
  display:flex;
  align-items: center;
}
.slider-counter{
  padding:1vw;
  background-color: white;
  color:black;
  height: 7%;
  display:flex;
  align-items: center;
  border-radius: 7px;
  font-size:15px;
  width: 50%;
  justify-content: center;
  font-family: $font-family-light;
  b{
    font-family: $font-family-bold;
    font-size:16px;
  }
}
.buttonn{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:40%;
  height: 25px;
  opacity:1;
  cursor: pointer;
  &.inactive{
    pointer-events: none;
    opacity:0.4;
  }
  &.backwards{
    background-image: url("../../assets/icons/arrow-left.svg");
  }
  &.forwards{
    background-image: url("../../assets/icons/arrow-left.svg");
    transform:rotate(180deg);
  }
}

.mobile-article-container {
  width: 100vw;
  h3 {
    padding: 5px;
    font-family: $font-family-Xbold;
  }
}

.mobile-article-text {
  padding: 10px;
  width: 100%;
  margin-left:1%;
  font-size: 17px;
  h2,
  h3,
  h4 {
    margin-left: 10px;
  }
}

.mobile-right-article-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vh;
}

.mobile-arrow-left {
  margin-top: 5%;
  margin-left: 3%;
  margin-bottom:5%;
  background-image: url("../../assets/icons/arrow-left.svg");
  background-size: contain;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .article-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 1200px) {
  .article-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .slider{
    width: 99%;
    margin-top:8%;
    margin-bottom:3%;
  }
  .slider-counter{
    height: 60%;
    width:25%;
  }
  .buttonn{
    height: 27px;
    &.forwards{
      margin-right:2%;
    }
    &.backwards{
      margin-left:2%;
    }
  }
}
