@import "../../base.scss";

.video-container{
  width:100vw;
  height: 100vh;
  // object-fit: fill;
  object-fit: cover;
  cursor: pointer;
}

.section-container {
  @extend %flex;
  height: 100vh;
  &.inverted {
    flex-direction: row-reverse;
  }
  // &.center {
  //   align-items: center;
  // }
  &.fullblended{
    background: #A02042;
  }
  &.opacity{
    // background-image:url("../../assets/images/homepage-section-1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.backgroundBlack{
    background-color: black;
  }
  &.bigger{
    height:160vh;
    width:100%;
  }
  &.semibigger{
    height:120vh;
    width:100%;
  }
  &.muchbigger{
    height:180vh;
    width: 100%;
  }
  &.verymuchbigger{
    height:200vh;
    width: 100%;
  }
  .container {
    flex: 1;
    @extend %flex;
    justify-content: center;
    align-items: center;
    &.backgroundBlack{
      background-color: black;
    }
    &.no-align{
      align-items: flex-start;
    }
    &.video{
      position:absolute;
      left:50%;
      height:100vh;
      width:50vw;
      max-width:50vw;
      background-color: rgba(0, 0, 0, 0.61);

      backdrop-filter: blur(10px);
    }
    &.opacity{
      background-color: rgba(0, 0, 0, 0.61);

      backdrop-filter: blur(10px);
    }
    &.special {
      flex-direction: column;
      justify-content: space-evenly;
      .image {
        height: 45%;
        width: 90%;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        &.smaller {
          background-size: 40%;
        }
        
        &.selected {
          border: solid 3px $lblue;
          box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.2);
        }
      }
    }
    &.black {
      background-color: #13121C;
      color: $white;
    }
    &.image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .main-image-wrapper {
        &.blended{
          background: linear-gradient(135deg, #780b24, #050508 50%);
        }

        .main-image {
          flex: 1;
          min-height: 0;
          height: 100%;
          object-fit: contain;
        }

        .mobile-main-image {
          min-height: 100%;
        }
      }
    }
    &.smaller{
      background-position: left;
      background-size: 90%;
      background-repeat: no-repeat;
    }
    &.muchsmaller{
      background-position: left;
      background-size: 75%;
      background-repeat: no-repeat; 
    }
    &.contain{
      background-size:contain;
      background-position: center;
    }
    &.blended{
      background: linear-gradient(135deg, #780b24, #050508 50%);
    }
    .container-inner {
      width: 80%;
      .profile{
        margin-bottom:5%;
        // width:40%;
        font-size:15px;
        height:40px;
        text-align: center;
        p{
          margin-top:2%;
          color: $white;
          font-size: 16px;
          font-family: $font-family-bold;
          text-align: left;
          width: fit-content;
          padding: 10px 15px;
          background-color: $red;
          border-radius: 5px;
          text-align: left;
          // width: 100%;
        }
        
      }
      .mobile-profile{
        min-width:100vw;
        background-color: $red;
        padding: 12px 15px;
        text-align: center;
        // margin: 5% -1.5%;

        p {
          font-family: $font-family-bold;
          font-size: 14px;
        }
      }
      .font-light {
        font-size: 18px;
        font-family: $font-family-light;
      }
      &.moveupinner{
        margin-top:-4%;
      }
    }
    &.moveupinner{
      margin-top:-15%;
    }
    &.content{
      width: unset;
      &.profile {
        .container-inner {
          width: 100%;
          padding: 2vw;
        }
      }
      &.content-full {
        height: 100%;
      }
    }
  }
  .half {
    width: 50%;
    object-fit: cover;
  }
  &.content-fit {
    display: flex;
    min-height: 100vh;
  }
}
.smallerVideo{
  height: 400px;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 900px) and (max-width: 1500px) {
  .section-container {
    .container.content {
      .container-inner {
        .line-and-text {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .section-container {
    height: 70vh;

    &.content-fit {
      min-height: 100vh;
      height: unset;
    }
  }
}

// zoomed
@media screen and (min-width: 900px) and (max-width: 1500px) and (max-height: 1400px) {
  .section-container  {
    height: 120vh;

    &.content-fit {
      min-height: 100vh;
      height: unset;
    }
  }

  .video-container {
    height: 120vh;
  }
}

//zoom test
// @media screen and (zoom: 1.5) {
//   .section-container {
//     all: initial;
//     height: 140vh;
//     background-color: pink !important;
//   }
// }

@media screen and (max-height: 800px) and (max-width: 1030px) {
  .section-container {
    height: 100vh;

    &.content-fit {
      min-height: 100vh;
      height: unset;
    }
  }
}

@media screen and (max-width: 900px) {

  #connecting{
    padding:25px 0 5px 0;
  }

  .section-container {
    flex-direction: column;
    align-items: center;
    // width: 100vw;
    height: fit-content !important;
    max-height: fit-content;
    position: relative;

    &.content-fit {
      min-height: 100vh;
      height: unset;
    }

    .container.image.relative {
      // width: 93%;
      width: 100%;
      min-height: 350px;
    }

    .container.content {
      position: relative;
      // min-width: 100vw;
      width: unset;
      width: 100%;
      height: unset;
      // padding: 0 2vw;
      // margin: 0 auto;

      &.video {
        width: 100vw;
      }
      &.padding {
        padding: 0 20px !important;
      }
      // &.image {
      //   // width: 95vw;
      //   background-size: 95%;
      // }

      &.profile {
        .container-inner {
          padding: 0;
        }
      }

      .container-inner {
        // min-width: 100%;
        min-width: 100%;
        padding: 0;

        h5 {
          text-align: left;
          margin-left: 0;
        }

        .title-container {
          margin-left: 0;
          h1 {
            font-size: 22px;
            margin-left: 0;
          }
        }

        .title,
        .line-and-text {
          &.padding {
            padding: 0 20px;
          }
        }

        .line-and-text {
          p {
            font-size: 16px;
            padding: 0;
          }
          .lower-title {
            font-size: 18px;
            margin-bottom: 20px;
          }
        }

        .lower-title {
          font-size: 18px;
        }

        .flex-wrapper {
          p {
            font-size: 16px;
            padding: 0;
          }
        }
      }
    }
  }
}
