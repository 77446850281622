@import "../../base.scss";
.button-container{
    font-family: $font-family-Semibold !important;
    font-size:16px !important;
}

.property-component-container {
    .preview {
        display: -webkit-box;
        overflow : hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        margin-left: 0;
    }
}
