@import "../../base.scss";

.home-container{
    width:100vw;
}

.line-and-tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width:100%;
    flex-wrap:wrap;
    .linee-and-text{
        display: flex;
        align-items: center;
        margin-bottom:2%;
        &.bigger{
            width:50%;
        }
        .leftt-line{
        border: 2px solid #E5214E;
        background-color: #E5214E;
        height: 4vh;
        margin-left:5%;
        }
        .tab-text{
        font-family: $font-family-bold;
        white-space: nowrap;
        &.smaller{
            font-size:13px;
        }
        }
        p{
        padding: 0 9px;
        }
    }
}


.content-container {
   
    &.not-blend{
      background: #13121C;
    }
}

