@import "../../base.scss";

.innovations-container {
  .section-container {
    .container-inner {
      .profile {
        p {
          margin-top: 2%;
          color: $red;
          font-size: 20px;
          text-transform: uppercase;
          font-family: $font-family-light;
          background-color: transparent;
          padding: 0;
          // width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .section-container {
    .container-inner {
      .profile {
        p {
          padding: 0 20px;
        }
      }
    }
  }

  .innovations-container {
    .tabs-component-container {
      .mobile-tabs-container {
        p,
        .upper-mobile-title,
        .inner-mobile-title {
          padding: 0 20px;
          margin-left: 0;
        }

        p {
          font-size: 14px;
        }

        .normal-tab-text {
          font-size: 16px;
        }
      }
    }
  }
}
