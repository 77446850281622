@import "../../base.scss";


label{
  cursor: pointer;
}
.button-right{
  display: flex;
  justify-content: flex-end;

}

.container{
  font-family: $font-family-light;
}
.contact-component-container {
  height: 100vh;
  display: flex;
  &.home{
    width:100%;
    .right-container{
      width:85%;
    }
  }
  .left-container {
    width: 60%;
    background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/about-section-2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    order: 1;
  }
  .right-container {
    width: 60%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
    p {
      font-family: $font-family-light;
      font-size: 20px;
      text-align: center;
    }
    .inner-container {
      width: 85%;
      height: 70%;

      .contact-subtitle {
        font-size: 22px;
      }
      .flex-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        .form-input-wrapper {
          width: 45%;
          border-bottom: solid 1px $white;
          height: 35px;
          .form-input {
            width:100%;
            background-color: transparent;
            height: 35px;
            font-size: 18px;
            color: $white;
            border: none;
            font-family: $font-family-light;
          }
        }
        .form-textarea-wrapper {
          width: 100%;
          height: 100px;
          border-bottom: solid 1px $white;
          textarea {
            max-width: 100%;
            background-color: transparent;
            height: 100%;
            width: 20vw;
            color: white;
            border: none;
            font-family: $font-family-light;
            font-size: 18px;
            min-height: 50px;
          }
        }
      }
      .flex-end {
        display: flex;
        justify-content: space-between;
        .checkboxes{
          display:block;
          label{
            font-size:15px;
          }
        }
        .flex-container {
          align-items: center;
          cursor: pointer;
          p {
            padding: 0;
            font-family: $font-family-light;
            margin-right: 15px;
            font-size: 18px;
          }
          .send-icon {
            width: 26px;
            height: 26px;
            //background-image: url("../../assets/icons/send-button.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .button-container{
    &.mobilee{
      text-align: center;
    }
  }
  u{
    font-size:14px;
  }
  .contact-component-container {
    height: unset;
    flex-direction: column;
    
    .right-container {
      width: 100%;
      padding: 40px 0;
      .inner-container {
        width: 100%;
        padding: 0 20px;
        .flex-container {
          flex-direction: column;
          margin-bottom: 0;
          .form-input-wrapper {
            width: 100%;
            margin-bottom: 40px;

            .form-input {
              font-size: 14px;
              font-family: $font-family-light;
              color: $white;
            }
          }
          .form-textarea-wrapper {
            height: unset;
            .form-input {
              font-size: 14px;
              font-family: $font-family-light;
              color: $white;
            }
          }
          &.mobile {
            flex-direction: row;
            margin-top: 40px;
          }
        }
        .contact-subtitle {
          font-size: 18px;
          font-family: $font-family-light;
        }
      .flex-end{
        margin-top:15px;
        display:flex;
        .checkboxes{
          padding:10px 0px;

        }
      }
      }
    }
    .left-container {
      height: 50vh;
      width: 100%;
    }
  }
}

input[type="checkbox"]{
  margin-left: 10px;
  display: none;  
  
  }
  
  label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-radius: 3px;
    background-color: black;
    border: 1px solid white;
  }
  
  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 15px;
    border-color:white;
  }
  .checkbox label {
    margin-bottom: 10px;
  }
  
   .checkbox label:before {  
      border-radius: 3px;  
  }  
  
  input[type="checkbox"]:checked + label:before {  
      content: "\2713";  
      text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);  
      font-size: 15px;  
      color: white;
      text-align: center;  
      line-height: 15px;  
  }