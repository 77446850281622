@import "../../base.scss";
.main-component-container {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-family: $font-family-light;
    }
    .green-arrow-right {
      width: 26px;
      height: 26px;
      //background-image: url("../../assets/icons/green-arrow-right.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 10px;
    }
  }
  .video-container {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: -3px;
      visibility: visible !important;
    }
  }
  &.right {
    background-position-x: right;
    background-size: 50%;
  }
  &.small-pic {
    background-size: cover;
    background-position: left;
  }
  &.reversed {
    &.right {
      background-position: left;
    }
    .overlay-container {
      background-color: #070e22;
      color: white;
      &.left {
        left: 0;
        background-color: white;
        color: black;
      }
      &.opacity {
        background-color: rgba(0, 0, 0, 0.61);

        backdrop-filter: blur(5px);
      }
    }
  }
  .overlay-container {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &.opacity {
      background-color: rgba(0, 0, 0, 0.61);
      color:white;
      backdrop-filter: blur(5px);
    }
    &.left {
      left: 0;
      background-color: #070e22;
      color: white;
    }
    .text-inner-container {
      min-width: 80%;
      max-width: 80%;
      width: 80%;
      .text-container {
        margin-bottom: 40px;
        h3 {
          font-family: $font-family-regular;
          margin-bottom: 20px;
          font-size: 24px;
        }
        p {
          font-family: $font-family-light;
          font-size: 20px;
        }
        .download{
          margin-top:10%;
        }
        .line-and-text{
          display: flex;
          flex-direction: row;
          align-items: center;
          .left-line{
            border: 2px solid #00D297;
            height: 4vh;
          }
          p{
            padding: 0 12px;
          }
        }
      }
      .additional-elements-container {
        display: flex;
        .app-store{
          background-image: url("../../assets/icons/App-Store.svg");
          height:70px;
          width:150px;
          background-size:100%;
          background-repeat: no-repeat;
          margin-right: 30px;
          cursor: pointer;
        }
        .google-play{
          background-image: url("../../assets/icons/Google-Play.svg");
          height:70px;
          width:150px;
          background-size:100%;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .main-component-container {
    &.reversed {
      &.right {
        background-size: 65%;
      }
    }
    .overlay-container {
      .text-inner-container {
        .text-container {
          h3 {
            font-size: 21px;
          }
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .main-component-container {
    &.reversed {
      &.right {
        background-size: 75%;
      }
    }
    .overlay-container {
      .text-inner-container {
        .text-container {
          h3 {
            font-size: 19px;
          }
          p {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .main-component-container {
    display: flex;
    flex-direction: column-reverse;
    height: unset;
    .video-container {
      width: 100%;
      height: 40vh;
    }
    .main-text-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 40vh;
      &.mobileRight{
        background-position: right;
      }
      &.mobileLeft{
        background-position: left;
      }
    }
    .overlay-container {
      width: 100vw;
      height: auto;
      padding: 40px 0;
      background-color: white;
      .text-inner-container {
        max-width: 90%;
        min-width: 90%;
        .text-container {
          h3 {
            font-size: 22px;
          }
          p {
            font-size: 16px;
          }
        }
        .additional-elements-container {
          justify-content: space-between;
          .app-store-container {
            width: 160px;
            height: 65px;
            .apple-icon {
              width: 50px;
              height: 50px;
            }
            .apple-text {
              p {
                font-size: 11px;
              }
              #bigger {
                font-size: 15px;
              }
            }
          }
          .google-store-container {
            width: 160px;
            height: 65px;
            .google-icon {
              width: 50px;
              height: 50px;
            }
            .google-text {
              p {
                font-size: 10px;
              }
              #bigger {
                font-size: 14px;
              }
            }
          }
        }
      }
      &.left {
        background-color: #070e22;
      }
      &.opacity {
        background-color: white;
      }
    }
  }
}
