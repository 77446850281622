@import "../../base.scss";

.list-container {
  min-height: fit-content;
  .container-inner {
    padding-left: 0 !important;

    .text-outer-wrapper {
        position: relative;
        padding: 15px 0;

        .line {
            position: absolute;
            top: 20px;
            bottom: 0;
            padding-left: 2.4%;
            z-index: 0;
            height: 100%;
        }

      .row-wrapper {
        z-index: 1;
        // padding: 0 20px;
        margin-bottom: 25px;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-family: $font-family-Semibold;
          color: white;
          padding: 25px;
          border-radius: 50%;
          background-color: $black;
          border: 1px solid $red;
          height: 25px;
          width: 25px;
        }
        .text {
            padding-top: 10px;
            font-size: 22px;
            font-family: $font-family-light;
        }
      }
    }
    .bottom-text {
        font-size: 16px;
        font-family: $font-family-light;
        margin-bottom: 50px;
    }
  }
}



@media screen and (max-width: 1500px) {
  .list-container {

    .container-inner {

      .text-outer-wrapper {

        .line {
          padding-left: 3.7%;
        }

        .row-wrapper {
          .number {
            font-size: 18px;
            margin-bottom: 10px;
            margin-left: 0;
          }
  
          .flex-title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100vw;
            // height: 30px;
          }

          .text {
            font-size: 16px;
            padding-top: 0;
          }
        }

        .img-wrapper {
          min-width: 100%;
          // margin-bottom: 10px;
          .line.horizontal {
            all: initial;
            // transform: rotate(90deg);
            position: relative;
          }
        }
      }

      .bottom-text {
        padding-left: 20px;
        padding-top: 15px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .list-container {

    .container-inner {

      .text-outer-wrapper {

        .line {
          padding-left: 4%;
        }
      }

      .bottom-text {
        padding-left: 20px;
        padding-top: 15px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .list-container {
    height: fit-content;
    padding: 0;

    .container-inner {
      height: unset;
      padding: 0;

      .text-outer-wrapper {
        padding-top: 0;

        .row-wrapper {
          height: fit-content !important;
          flex-direction: column;
          width: 100vw;
          margin-bottom: 5%;

          &.last {
            margin-bottom: 0;
          }

          p {
            padding-left: 20px;
          }

          .number {
            font-size: 18px;
            margin-bottom: 10px;
            margin-left: 20px;
          }
  
          .flex-title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100vw;
            // height: 30px;
          }

          .text {
            font-size: 16px;
            padding-top: 0;
          }
        }

        .img-wrapper {
          min-width: 100%;
          // margin-bottom: 10px;
          .line.horizontal {
            all: initial;
            // transform: rotate(90deg);
            position: relative;
          }
        }
      }

      .bottom-text {
        padding-left: 20px;
        padding-top: 15px;
        font-size: 14px;
      }
    }
  }
}
