@import "../../base.scss";

.news-container {
    background-color: black;
    display: flex;
    flex-wrap: wrap;
  }
  
.read-more{
  font-size:15px;
  cursor:pointer;
  color:#E5214E;
}

  .vertical-tab-buttons {
    border-left: solid 3px white;
    padding:2px;
  }
  
  .layer{
    height: 50vh;
    background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/FightScout-Banner.png");
    background-size: cover;
    flex-basis: 50%;
    background-position: center;
    border-left:3px solid #E5214E;
  }

  
  .vertical-news {
    display: flex;
    font-family: $font-family-medium;
    background-color: black;
    color: $white;
    flex-basis: 50%;
    height: 50vh;
    &.line{
      border-left:3px solid #E5214E;
    }
    
  }
  
    .news-image{
      background-position: 50%;
      background-repeat: no-repeat;
      width: 60%;
      height: 100%;
      background-size: cover;
      &.right{
          width:65%;
      }
    }
  

.mobile-text-container{
  &.news{
    width:60%;
    padding:30px;
  }
  .date-news{
      font-size:14px;
  }
}

    .text-container{
      display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      &.news{
        width:60%;
        padding:30px;
      }
      .date-news{
          font-size:14px;
      }
     
    }
  
    a{
      font-size:13px;
      color:$white;
      cursor: pointer;
    }



    @media screen and (max-width: 900px) {
      
         .title-news{
           margin-left:0%;
         }

      .section-container{
        width:100%;
        .container{
          &.image{
            background-repeat: no-repeat;
            background-position:center;
          }
            }
        .title-container{
          margin-left:5px;

          h1{
            font-size:27px;
          }
          .line{
            width: 20%;
            max-width: 30%;
            height: 3px;
            background-color: $blue;
            margin-bottom: 15px;
          }
        }
  
        .container{
          display:flex;
          flex-direction: row;
          .container-inner{
           width:100vw;
           .vertical-tab-buttons{
             width:100%;
             .tab-text{
               padding: 0 20px;
               white-space:normal;
               font-size:15px;
               margin-bottom:40px;
             }
           }
           }
         }
    }
    .news-container {
      width:100vw;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height:unset;
      .vertical-news{
        display:block;
        width:100%;
        height:unset;
        &.line{
          border-left: none;
          border-bottom: 3px solid $red;
        }
        .news-image{
          height:40vh;
          width:100vw;
          }
        .text-container{
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
          // height:35vh;
          .date-news {
            margin: 0;
            padding: 0;
            font-family: $font-family-bold;
            margin-bottom: 25px;
          }
          
          .title-news {
            font-size: 16px !important;
            font-family: $font-family-bold;
            line-height: 24px;
          }

          .read-article {
            font-size: 16px;
            font-family: $font-family-light;
            margin-bottom: 25px;
          }
        }
      }
    }
  }