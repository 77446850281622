@import "../../base.scss";

.video-container {
  width: 100vw;
  height: 100vh;
  // object-fit: fill;
  object-fit: cover;
  cursor: pointer;
}

.new-container {
  width: 100vw;
  // height: 245vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-section-container {
  @extend %flex;
  height: 100vh;
  max-height: fit-content;
  width: 100vw;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.899);
  // background-image: url("../../assets/images/Our-Universe-Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &.inverted {
    flex-direction: row-reverse;
  }
  &.blended {
    background: linear-gradient(135deg, #780b24, #050508 50%);
  }
  &.fullblended {
    background: #a02042;
  }
  &.opacity {
    // background-image:url("../../assets/images/homepage-section-1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.backgroundBlack {
    background-color: black;
  }
  &.bigger {
    height: 160vh;
    width: 100%;
  }
  &.semibigger {
    height: 120vh;
    width: 100%;
  }
  &.muchbigger {
    height: 180vh;
    width: 100%;
  }
  &.verymuchbigger {
    height: 200vh;
    width: 100%;
  }
  .container {
    flex: 1;
    @extend %flex;
    justify-content: center;
    align-items: center;
    &.backgroundBlack {
      background-color: black;
    }
    &.desktop {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-width: 100vw;
    }
    &.no-align {
      align-items: flex-start;
    }
    &.video {
      position: absolute;
      left: 50%;
      height: 100vh;
      width: 50vw;
      background-color: rgba(0, 0, 0, 0.61);

      backdrop-filter: blur(10px);
    }
    &.opacity {
      background-color: rgba(0, 0, 0, 0.61);

      backdrop-filter: blur(10px);
    }

    &.black {
      background-color: #13121c;
      color: $white;
    }
    &.image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.smaller {
      background-position: left;
      background-size: 90%;
      background-repeat: no-repeat;
    }
    &.muchsmaller {
      background-position: left;
      background-size: 75%;
      background-repeat: no-repeat;
    }

    &.contain {
      background-size: contain;
      background-position: center;
    }
    &.blended {
      background: linear-gradient(135deg, #e5214e, #050508 53%);
    }
    .container-inner {
      width: 80%;
      &.center {
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .main-image-map-wrapper {
        display: inline-block;
        fill: transparent;
        position: relative;
      }

      .img-links-wrapper {
        position: relative;
      }

      .main-image {
        width: auto;
        // height: 100%;
        object-fit: contain;

        // test
        // position: absolute;
        // display : block;
        // height : auto;
        // max-width : 100%;
      }
    }
  }
}
.smallerVideo {
  height: 350px;
}
.blended {
  background: linear-gradient(135deg, #780b24, #050508 50%);
}

@media only screen and (max-width: 900px) {
  .image-section-container {
    margin-bottom: 15%;
    .container {
      .container-inner {
        width: 100%;
      }

      .main-image-wrapper {
        &.image-component-mobile {
          position: relative;
          height: fit-content;
          display: flex;
          flex-direction: column;
          // height: 280vh;

          .line {
            width: 100px;
            height: 90%;
            position: absolute;
            left: 25vw;
            top: 15vw;
            z-index: 0;
            border-right: 1px dashed rgba(255, 255, 255, 0.264);
          }

          .background-img {
            z-index: 0;
            width: 90%;
            min-height: 100%;
            margin: 0 20px;
          }
          // .background-img {
          //   z-index: 0;
          //   width: 80%;
          //   min-height: 100%;
          //   margin: 0 auto;
          //   background-image: url('../../assets/images/FightScout-Homepage-Our-universe.png');
          //   background-size: cover;
          //   background-position: center;
          //   background-repeat: no-repeat;
          // }
        }

        .circles-wrapper {
          // position: absolute;
          height: fit-content;
          // top: 21%;
          // bottom: 0;
          // gap: 157px;

          // .main-image {
          //   // position: absolute;
          //   // display: inline-block;
          //   // min-width: 25%;
          //   // min-height: 25%;
          //   min-width: 270px;
          //   min-height: 270px;
          //   margin: 0 auto;
          //   left: 10%;
          //   // background-color: pink;
          //   border-radius: 50%;
          //   cursor: pointer;
          //   z-index: 2;
          // }
        }

        // #main-image-1 {
        //   top: 26%;
        //   right: 43%;
        //   top: calc(10vh + 13%);
        // }
        .main-image {
          z-index: 2;
        }
      }

      .lower-title {
        padding: 0 20px;
      }
    }
  }
  #connecting {
    padding: 25px 0 5px 0;
  }
}
