@import "../../base.scss";
@keyframes select {
  0% {
    border-bottom: solid 3px transparent;
  }
 
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  font-family: $font-family-regular;
  font-size: 18px;
  margin: 50px 0;
  white-space: nowrap;
  height:45px;
  background-color:#1F1D2B;
  color:white;
  width:100%;
  &.wide {
    max-width: unset;
    min-width: unset;
    margin: 30px 0;
    .tab-element {
      
      white-space: nowrap;
      margin-right: 15px;
    }
    :last-child {
      margin-right: 0;
    }
  }
  &.howitworks{
    display:flex;
    flex-direction: column;
  }
  .tab-element:first-child{
    margin-left:5%;
  }
  .tab-element:last-child{
    margin-right:5%;
  }
 
  .tab-element {
    display: flex;
      align-items: center;
      justify-content: center;
    padding: 5px;
    cursor: pointer;
    font-family: $font-family-light;
    text-align: center;
    width:100%;
    &.selected {
      animation-name: select;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      background-color:#E5214E;
      color:white;
      border-radius: 5px;
      font-family: $font-family-bold;
    }
  }

}



@media only screen and (max-width: 1750px) {
  .tabs-container {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1450px) {
  .tabs-container {
    font-size: 14px;
  }
}
