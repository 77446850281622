@import "../../base.scss";

.title-container {
  display: inline-block;
  padding:0px;
  h1 {
    font-family: $font-family-Semibold;
    padding: 0;
    font-size: 36px;
  }
  h3{
    font-family: $font-family-Semibold;
    font-size:30px;
  }
  #abc{
    font-size:34px;
  }
  &.small {
    h1 {
      font-size: 24px;
    }
  }
  &.mobile-special {
    h1 {
      font-size: 26px;
    }
  }
}

.top{
  margin-top:-5%;
}

@media only screen and (max-width: 1750px) {
  .title-container {
    h1 {
      font-size: 30px;
      line-height: 1.3em;
    }
    h3{
      font-size: 26px;
      line-height: 1.3em;
    }
    &.small {
      h1 {
        font-size: 21px;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .title-container {
    h1 {
      font-size: 30px;
      line-height: 1.3em;
    }
    h3{
      font-size:23px;
      line-height: 1.3em;
    }
    &.small {
      h1 {
        font-size: 19px;
      }
    }
  }
  
}


@media only screen and (max-width: 900px) {
  .title-container{
    &.smaller-title{
      h1{
        font-size:28px;
      }
    }
    &.mobile {
      h1{
        padding: 0 20px;
      }

      h2 {
        font-size: 18px;
        font-family: $font-family-light;
      }
    }

    h1 {
      font-size: 22px;
    }
    h3{
      font-size:22px;
      margin-bottom: 15px;
      // margin-left: 2%;
      // margin-top: 3%;
    }
    h5{
      font-size: 18px;
      // margin-left: 2%;
      // margin-top: 3%;
    }
  }   
}

