@import "../../base.scss";

.inner-title{
  font-family: $font-family-light;
  font-size:19px;
  margin-left:5%;
}

.tabs-component-container {
  width: 100%;
  height: 101vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1F1D2B;
  color: white;
  .content-container {
    background: linear-gradient(135deg, #E5214E, #050508 53%);
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
      &.not-blend{
        background: #13121C;
      }
    .title-container{
      margin-left:5% !important;
    }
    .text-inner-container {
      width:100%;
      height:70%;
      p {
        font-size: 20px;
        font-family: $font-family-light;
      }
      .normal-tab-text{
        font-family: $font-family-light;
        width:80%;
        margin-left:5%;
        &.bottom{
          margin-bottom:5%;
        }

      }
      
      .line-and-text{
        display: flex;
        flex-direction: row;
        align-items: center;
        .left-line{
          border: 2px solid #E5214E;
          height: 4vh;
          margin-left:5%;
        }
        .tab-text{
          font-family: $font-family-bold;
          width:80%;
          &.light{
            font-family: $font-family-light;
          }
        }
        p{
          padding: 0 12px;
        }
      }
    }
  }
  .video-container {
    width: 50%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: -3px;
      visibility: visible !important;
    }
  }
  .image {
    height: 100%;
    width: 50%;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    z-index: 1000;
  }
}

.mobile-tabs-container{
  width:100%;
  &.not{
    background: #100f18;
  }
  .upper-mobile-title{
    margin-left:2%;
    font-size:22px;
    font-family: $font-family-Semibold;
  }
  .inner-mobile-title{
    padding-right: 20px;
    font-size:18px;
    font-family: $font-family-light;
  }
  .background-tab{  
    display: flex;
    justify-content: center;
    align-items: center;
    height:5vh;
    width:100%;
    background-color: #E5214E;
    color:white;
    text-align: center;
    font-family: $font-family-bold;
  }
}

@media only screen and (max-width: 1750px) {
  .tabs-component-container {
    .content-container {
      .text-inner-container {
        p {
          font-size: 17px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .tabs-component-container {
    .content-container {
      .text-inner-container {
        p {
          font-size: 16px;
        }
      }
    }
  }
}

// @media only screen and (min-width: 900px) and (max-width: 1200px) {
//   .tabs-component-container {
//     height: 80vh;
//   }
// }

@media only screen and (max-width: 900px) {
  .tabs-component-container {
    height: unset;
    flex-direction: column;
    background-color: #13121C;
    .text-container {
      width: 90%;
      h3 {
        font-family: $font-family-regular;
        margin-bottom: 20px;
      }
      p {
        font-family: $font-family-light;
      }
      margin-bottom: 20px;
      &.margin {
        margin: 40px 0;
      }
    }
    .normal-tab-text {
      padding: 0 20px;
      line-height: 24px;
    }
    .image {
      width: 100%;
      height: 40vh;
    }
    .video-container {
      width: 100%;
      height: 40vh;
    }
  }
}
