@import "../../base.scss";

.home-container{
    width:100vw;
}

.line-and-tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width:100%;
    flex-wrap:wrap;
    .linee-and-text{
        display: flex;
        align-items: center;
        margin-bottom:2%;
        .leftt-line{
        border: 2px solid #E5214E;
        background-color: #E5214E;
        height: 4vh;
        margin-left:5%;
        }
        .tab-text{
        font-family: $font-family-bold;
        width:200px;
        &.smaller{
            font-size:13px;
        }
        }
        p{
        padding: 0 12px;
        }
    }
}



.content-container {
   
    &.not-blend{
      background: #13121C;
    }
}

@media only screen and (max-width: 900px) {
    .profile-container {
        min-height: 100%;

        .content-container {
            height: unset;
        }

        .lower-title {
            font-size: 18px;
            font-family: $font-family-light;
        }
    }

    .line-and-tabs {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .box-wrapper {
            padding: 10px;
            .tab-text.smaller {
                font-size: 14px;
            }
        }
    }
}

