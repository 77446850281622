@import "../../base.scss";

.partners-container {
  // height: 100vh;
  max-height: fit-content;
  width: 100vw;
  align-items: center;
  justify-content: center;
  //   background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Our-Universe-Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 30px;
  .slide-arrow-left {
    background-image: url("../../assets/icons/arrow-left-red.svg");
    background-size: cover;
    width: 30px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
.slick-arrow {
  display: none !important;
}

@media screen and (max-width: 900px) {
  .partners-container {
    background-color: #13121c;
    height: unset !important;
  }
}
