@import "../../base.scss";

.Tabs-Contact {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .section-container {
    &.howit {
      width: 95%;
    }
  }
}

.contact-component-container {
  width: 100%;
}
.Full-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: black;
  flex-direction: row;
  .Left-container {
    width: 49.5%;
    height: 100%;
    background-color: black;
    .tabs-container {
      background-color: black;
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      margin: 0;
      padding: 2vw;
      .tab-element {
        border: none;
        text-align: left;
        height: 6%;
        font-size: 24px;
        font-family: $font-family-medium;
        width: 95%;
        margin-left: 1%;
        margin-bottom: 4%;
        justify-content: flex-start;
        &.selected {
          background-color: #e5214e;
          font-family: $font-family-bold;
        }
      }
    }
  }
  .Right-container {
    width: 45%;
    .Questions {
      color: white;
      li::marker {
        content: none;
      }
    }
    .leftt-line {
      border: 2px solid #e5214e;
      background-color: #e5214e;
      border-radius: 5px;
      height: 3vh;
      width: 0%;
      margin-right: 2%;
    }
    .question-and-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4%;
      .icon {
        color: #e5214e;
        &.rotated {
          transform: rotate(180deg);
          i {
            color: white;
          }
        }
      }
      .question {
        width: 99%;
        text-align: left;
      }

      i {
        width: 50px;
        height: 50px;
      }
    }
    .answer {
      margin-left: -3%;
      margin-top: 5%;
    }
  }
}

.section-container.statistics {
  justify-content: center;
  align-items: center;
  // justify-content: space-between;
  // height: 100%;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  // padding: 0 2%;
  background-color: $black;
  margin-bottom: 15%;

  &.no-margin {
    margin-bottom: 0;
  }

  .container.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: fit-content;

    margin-left: 5%;
    margin-right: 5%;
  }
  .container.content {
    width: 100%;
    padding-top: 5%;
  }
  .container-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    width: 100%;

    .left-container {
      flex: 1;
      // background-color: #13121C;
      height: 100%;
      padding: 0 2%;
      padding-left: 0;
      // height: 50vw;
      align-self: center;

      .title {
        font-size: 36px;
        font-family: $font-family-Semibold;
      }

      .profiles-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .option {
          display: flex;
          @extend %border-styles;

          .profile-name {
            font-size: 20px;
            font-family: $font-family-light;
          }
          .profile-value {
            font-size: 20px;
            font-family: $font-family-BoldItalic;
            font-family: $font-family-bold;
          }
        }
      }
    }
    .right-container {
      flex: 1;
      padding: 0 2%;
      background-color: $black;
      padding-right: 0;

      .bold {
        font-family: $font-family-bold;
      }
      .light {
        font-family: $font-family-light;
      }

      .testimonials-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: $black;
        width: 100%;
        max-height: 70vh;
        height: 100%;
        overflow-y: auto;
        padding: 0;
        // padding: 5px;
        // padding-right: 0 !important;
        @include scrollbarBlack;

        .testimonial {
          @extend %border-styles;

          .thumbnail {
            width: 90px;
            height: 90px;
            border-radius: 5px;
          }
          .text-wrapper {
            align-items: stretch;
            height: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 2600px) {
//     .section-container.statistics {

//     }
// }

@media screen and (max-width: 1600px) {
  .Full-container {
    .Left-container {
      .tabs-container {
        .tab-element {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .section-container.statistics {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 15%;

    .container.content {
      justify-content: center;
      margin-left: 5%;
      margin-right: 5%;
    }

    .container-inner {
      // width: 90%;
    //   padding: 0 3.5%;
      gap: 10px;

      .left-container {
        flex: 1;
      }
      .right-container {
        flex: 1;
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .section-container.statistics {
    // height: 70vh !important;
    min-height: 70vh;
    max-height: 100vh;
    margin-bottom: 0;

    .container.content {
      padding: 5% 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .Full-container {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-flow: column;
  }

  .section-container.statistics {
    padding: 0 20px;
    background-color: #13121c;

    .container.content.profile {
      .container.profile {
        padding: 0 20px;
      }
    }
    .container-inner {
      flex-direction: column;
      height: fit-content;
      padding: 0 20px;

      .left-container {
        padding-right: 0;
        width: 100%;

        h4 {
          font-size: 18px;
          margin: 0;
          margin-bottom: 20px;
        }
        .title {
          font-size: 22px;
        }

        .profiles-wrapper {
          .option {
            .profile-name {
              font-size: 14px;
            }
            .profile-value {
              font-size: 14px;
              font-family: $font-family-SemiBoldItalic !important;
              font-family: $font-family-Semibold !important;
            }
          }
        }
      }

      .right-container {
        padding: 0;
        height: 20vh;
        overflow: scroll;
        .testimonials-wrapper {
          height: fit-content;
          overflow: visible;
          background-color: #13121c;

          .testimonial {
            background-color: #050508;
            .border-solid {
              border: 1px solid $red;
            }
            .thumbnail {
              width: 55px;
              height: 55px;
              border-radius: 5px;
              border: 1px solid $white;
            }
            .text {
              &.light {
                font-size: 16px;
              }
              &.bold {
                font-size: 16px;
                font-family: $font-family-Semibold;
              }
            }
          }
        }
      }
    }
  }
}
