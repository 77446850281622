@import '../../base.scss';

.radio-label {
  height: fit-content;
  margin-right: 15px;
  text-align: left;
  font-size: 16px;
  font-family: $font-family-light;
  text-decoration: underline;
  color: white;

  &.square {
    // padding-left: 0;
    padding-left: 11%;
    .custom-radio,
    .custom-radio::after {
      // display: none;
      border-radius: 10%;
    }

    .custom-radio {
      width: 18px;
      height: 18px;
      border: 1px solid white;
    }

    .custom-radio::after {
      display: none;
      width: 19px;
      height: 19px;
    }
  }

  &::before {
    display: none;
  }

  label {
    height: fit-content;
  }

  .custom-radio {
    // left: -8px;
    top: 6px;
    cursor: pointer;
    width: 22px;
    height: 22px;
    border: 1px solid white;
    background-color: $black;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 5px;
  }

  .radio-text, #text {
    all: initial;
    font-size: 16px !important;
    font-family: $font-family-light;
    text-decoration: underline;
    color: white;
  }
  
  /* for inner filled circle */
  .custom-radio::after {
    content: '';
    width: 17px;
    height: 17px;
    background: $white;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -52%);
    opacity: 0;
    transition: opacity 0.2s;
  }

  .custom-radio::after {
    display: none;
    width: 19px;
    height: 19px;
  }

  .radio-input {
    display: none;
  }
  
  .radio-input:checked + span {
    border: 1px solid white;
    background-color: white;
  }
  
  .radio-input:checked + span:after {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .radio-label {
    height: fit-content;
    margin-bottom: 15px;
    text-align: left;
    padding-left: 0;
  
    &.square {
      height: fit-content;
      margin-bottom: 15px;
      text-align: left;
      padding-left: 0;
      
      .custom-radio,
      .custom-radio::after {
        border-radius: 10%;
      }
    }
  
    label {
      height: fit-content;
    }
  
    .custom-radio {
      width: 30px;
      height: 30px;
    }
    
    /* for inner filled circle */
    .custom-radio::after {
      width: 25px;
      height: 25px;
      transform: translate(-50%, -50%);
    }
  }
}
