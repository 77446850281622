@import "../../base.scss";

.home-container{
    width:100vw;
}

.line-and-tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width:100%;
    flex-wrap:wrap;

    &.padding {
        padding: 0 20px;
    }

    .linee-and-text{
        display: flex;
        align-items: center;
        margin-bottom:2%;
        .leftt-line{
        border: 2px solid #E5214E;
        background-color: #E5214E;
        height: 4vh;
        margin-left:5%;
        border-radius: 5px !important;
        }
        .tab-text{
        font-family: $font-family-bold;
        width:200px;
        &.smaller{
            font-size:13px;
        }
        }
        p{
        padding: 0 12px;
        }
    }
    .box-wrapper {
        padding: 15px 20px;
        border: 1px solid $red-dark;
        border-radius: 5px;
        
        p {
            font-size: 16px;
            font-family: $font-family-light;
            padding: 0;
            margin: 0;
            text-transform: uppercase;
            color: $white;
        }
    }
}

.background-tab{
    font-size:16px !important;
}

.tab-image {
    flex: 1;
    min-height: 0;
    height: 100%;
    object-fit: contain;
    margin: auto;
}

.main-image {
    flex: 1;
    object-fit: contain;
    margin: auto;
    height: 100%;
}

.content-container {
   
    &.not-blend{
      background: #13121C;
    }
}


@media only screen and (max-width: 1100px) {
    .line-and-tabs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .box-wrapper {
            padding: 12px;
            .tab-text.smaller {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 900px) {

    .profile-container {
        min-height: 100%;
        overflow-x: hidden !important;
        h2 {
         font-family: $font-family-light;
        }

        .content-container {
            height: unset;
        }

        .section-container .container.content .container-inner .line-and-text .lower-title.fighters {
            margin-bottom: 12%;
        }
    }
    .background-tab{
        font-size:14px !important;
        font-family: $font-family-bold;
        margin-top: 35px;
    }

    .line-and-tabs {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .box-wrapper {
            padding: 10px;
            .tab-text.smaller {
                font-size: 14px;
            }
        }
    }
}
