@import "../../base.scss";

.section-container.slider {
  background-color: $grey;
  border: none;
  height: 100vh;
  min-height: fit-content;
  justify-content: center;
  width: 100%;

  &.innovations {
    background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Innovations-Background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-color: none;
    background-size: 100% 100%;
    min-width: 100vw;
    height: auto;
    margin-bottom: 0;
    padding-bottom: 8vw;
    padding-top: 8vw;
    padding-left: 5%;
    padding-right: 5%;
  }
  &.profile {
    height: 80vh;
  }

  .container.content {
    width: 100%;
  }

  .container-inner {
    width: 100%;
    &.wide {
      width: 97%;
    }
  }

  .title {
    font-size: 36px;
    font-family: $font-family-Semibold;
  }
  .smaller-title {
    font-size: 24px;
    font-family: $font-family-bold;
    text-transform: uppercase;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .subtitle {
    font-size: 18px;
    font-family: $font-family-light;

    &.big {
      font-size: 22px;
    }
  }

  .cards-wrapper {
    width: 100%;

    &.fighters-slider {

      .card-wrapper {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 20vw;
        height: 20vw;
        background-color: unset;
        padding: 0;
        width: 15vw;
        border: 1px solid $black;
        border-radius: 10px;
        margin-right: 0;
        background: rgba(0, 0, 0, 0.2);

        &.zoom {
          z-index: 3;
          overflow: hidden;
          .background-img {
            height: 130%;
            width: 100%;
            top: -6%;
            object-position: top 2% left 8%;
            border-radius: 10px;

            img {
              border-radius: inherit;
              z-index: 1;
            }
          }
        }
        &.zoom-extra {
          z-index: 3;
          overflow: hidden;
          .background-img {
            height: 180%;
            width: 100%;
            top: -46%;
            object-position: top 2% left 8%;
            border-radius: 10px;

            img {
              border-radius: inherit;
              z-index: 1;
            }
          }
        }
        &.zoom-center {
          z-index: 3;
          overflow: hidden;
          .background-img {
            height: 150%;
            width: 100%;
            top: -38%;
            left: 0;
            object-position: top 2% left 8%;
            border-radius: 10px;

            img {
              border-radius: inherit;
              z-index: 1;
            }
          }
        }
        &.zoom-right {
          z-index: 3;
          overflow: hidden;
          .background-img {
            height: 150%;
            width: 100%;
            top: -10%;
            object-position: top 2% left 50%;
            border-radius: 10px;

            img {
              border-radius: inherit;
              z-index: 1;
            }
          }
        }

        .smallest-title {
          font-size: 22px;
          text-align: left;
          color: $white;
          align-self: flex-start;
          font-family: $font-family-bold;
        }

        .background-img {
          position: absolute;
          left: 0px;
          top: 0px;
          object-fit: cover;
          z-index: -1;
          object-position: top center;
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }

        .bold {
          font-family: $font-family-bold;
          font-size: 20px;
        }

        .bottom {
          width: 100%;
          padding: 15px 20px;
          background-color: #050508d2;
          align-self: flex-end;
        }
      }
    }
    .card-wrapper {
      @extend %border-styles;
      margin: 0;
      // width: 16vw !important;
      width: 20vw;

      width: 100%;
      
      padding: 25px 20px;
      padding-top: 45px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      background-color: #050508b1;
      height: 80vh;
      min-height: fit-content;

      // height: calc(100vw - 50%);
      // height: fit-content;
      // min-height: calc(100vw - 50%);

      .top {
        display: flex;
        margin: auto 0;

        img {
          width: 155px;
          height: 155px;
        }

        .flex {
          h4 {
            font-size: 16px;
          }

          h5 {
            font-size: 12px;
          }
        }
      }

      .bottom {
        align-self: flex-end;
        margin-top: auto;
        h4 {
          font-size: 14px;
          font-family: $font-family-light;
          text-decoration-thickness: from-font;
          color: $red;
        }

        p {
          font-size: 16px;
        }
      }

      .fighter-slider-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .slick-slider.slick-initialized .slick-slide  {

    // > div {
    //   margin: auto;
    // }
    // &:first-child {
    //   > div {
    //     margin: auto;
    //     margin-left: 0;
    //     background-color: pink;
    //   }
    // }

    > div {
      margin: auto;
      margin-left: 2% !important;
    }
  }

  .slick-slider.slick-initialized {

    .slick-track {
      display: flex;
      justify-content: space-between;
    }
    .slick-list {
      min-height: fit-content;
    }

    .slick-dots {
      display: flex;
      justify-content: center;
      // margin-left: 26px;
      display: flex;
      justify-content: center;
      // width: 150px;
      // width: 100%;

      ul {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        // width: fit-content;
        li {
          min-width: 100%;
          height: 8px;

          .slider-inactive {
            width: 70px;
            height: 8px;
            border-radius: 8px;
            border: 1px solid $light-border;
            background-color: none;
          }
        }

        .slick-active {
          border-radius: 8px;
          background-color: $red;
          border: 1px solid $red;
          z-index: 1000;
          

          .slider-inactive {
            display: none;
          }
        }
      }
    }

    .slick-slide > div {
      display: grid;
      place-items: center;
      margin-top: 50px;
      margin: auto;
      margin-left: 10%;
      padding: 0px;
    }
  }

}

@media screen and (min-width: 2200px) {
  .section-container.slider {
    // margin-bottom: 15%;
    .container-inner {

      .slick-slider.slick-initialized .slick-slide > div {
        margin-left: 0;
      }

      .slick-slider.slick-initialized {
        .slick-list {
          margin-right: -2%;
        }
      }

      .cards-wrapper {

        .card-wrapper {

          .top {
            margin: auto 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 3000px) {
  .section-container.slider {
    // margin-bottom: 15%;
    .container-inner {

      .slick-slider.slick-initialized .slick-slide > div {
        margin-left: 10%;
      }

      .slick-slider.slick-initialized {
        .slick-list {
          margin-left: 1%;
        }
        .slick-track {
          display: flex;
          gap: 20px;
        }
      }

      .cards-wrapper {

        &.fighters-slider {

          .card-wrapper {
            min-height: 10vw;
            height: 10vw;
          }
        }

        .card-wrapper {

          .top {
            margin: auto 0;
          }
        }
      }
    }

    .container-inner.wide {
      width: 155%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .section-container.slider {
    .container-inner {
      width: 83%;
    }
    .container-inner.wide {
      width: 83%;
    }
  }
}

@media screen and (max-width: 1500px) {
  .section-container.slider {
    .container-inner.wide {
      width: 109%;

    }
    .cards-wrapper {
      &.fighters-slider {
        margin-left: 1%;
        .card-wrapper {
          gap: 20px;
          .smallest-title {
            font-size: 18px;
            margin-right: 10px;
            text-align: left;
            overflow-wrap: break-word;  
            word-wrap: break-word; 
            word-break: break-word;
            width: 60%;
          }

         .bottom.text-end {
          padding-left: 10px;
          padding-right: 10px;
          height: 30%;

          img {
            width: 30px;
          }

          h5 {
            font-size: 18px;
          }
         }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .section-container.slider {
    .container-inner {
      width: 100%;
    }
    .container-inner.wide {
      width: 94%;
    }
  }
}

@media screen and (max-width: 1400px) {
  .section-container.slider {
    .container-inner.wide {
      width: 100%;
    }
    .container-inner {
      width: 93%;
    }
    .cards-wrapper {
      &.fighters-slider {
        margin-left: 0;
        .card-wrapper {
          .smallest-title {
            font-size: 16px;
          }
        }
      }
    }
  }
}

// @media screen and (min-width: 901px) and (max-width: 1499px) and (min-height: 901px) and (max-height: 1399px) {
//   .section-container.slider {
//     .container-inner {
//       width: 90%;
//     }
//   }
// }

// @media screen and (min-width: 901px) and (min-width: 1199px) and (max-width: 1499px) and (max-height: 1199px) {
//   .section-container.slider {
//     .cards-wrapper {
//       width: 108%;
//       margin-left: -3%;
//       margin-top: 3%;
//   }
//   }
// }

// @media screen and (min-width: 1301px) and (max-width: 1399px) and (max-height: 1399px) {
//   .section-container.slider {
//     .container-inner {
//       width: 94%;
//     }
//   }
// }

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .section-container.slider {
    .container-inner {
      width: 91%;
    }

    .cards-wrapper {
      width: 108%;
      margin-left: -3%;
      margin-top: 3%;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .section-container.slider {
    height: 80vh;

    .cards-wrapper {
      &.fighters-slider {
        .card-wrapper {

          .smallest-title {
            width: 100%;
            font-size: 16px;
          }

         .bottom.text-end {
          padding-left: 10px;
          padding-right: 10px;
          height: 40%;

          h5 {
            font-size: 16px;
          }
         }
        }
      }
    }
  }

  .home-container .section-container.slider {
    &.innovations {
      .cards-wrapper {
        .card-wrapper {
          height: 100vh;
        }
      }

    }
  }
}

@media screen and (min-width: 1020px) and (max-width: 1050px) and (min-height: 1350px) and (max-height: 1400px) {
  .home-container .section-container.slider.innovations .cards-wrapper .card-wrapper {
    height: 60vh;
  }
}

@media screen and (max-width: 900px) {
  .section-container.slider {
    margin-bottom: 15%;

    &.innovations {
      background-image: none;
    }

    .container-inner.mobile,
    .container-inner {
      flex-direction: column;
      height: fit-content;
      height: 100%;
      position: relative;
      padding: 0 20px;

      .title {
        text-align: left;
        width: 100%;
        font-size: 22px;
        margin-left: 0;
        margin-bottom: 15px;
        font-family: $font-family-Semibold;
        // padding-left: 20px;
      }

      .subtitle {
        font-size: 18px;
        font-family: $font-family-light;
        margin-left: 0;
        text-align: left;
        margin-bottom: 5px;
      }
      .smaller-title {
        margin-left: 0;
      }

      .cards-wrapper.mobile {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 10px 0;

        .card-wrapper {
          width: 100%;
          flex: 1;
          height: 45%;
          // min-height: fit-content;

          &.not-home-page {
          padding: 0;
          border: 0;
          }

          &.zoom {
            z-index: 3;
            overflow: hidden;
            .background-img {
              height: 190%;
              width: 100%;
              top: -60%;
              object-position: top 2% left 8%;
              border-radius: 10px;
  
              img {
                border-radius: inherit;
                z-index: 1;
              }
            }
          }

          .smaller-title {
            font-size: 20px;
            font-family: $font-family-bold;
            height: fit-content;
          }

          .subtitle {
            font-size: 16px;
          }

          .background-img {
            width: 100%;
            object-fit: cover;
            height: 350px;
            border-radius: 10px;
            // border-radius: 0;
            // box-shadow: none;
          }

          .bottom.text-end {
            // margin-top: 20px;
            text-align: center;
            width: 100%;
            text-transform: uppercase;

            h4 {
              margin-left: 0;
              margin-bottom: 0;
            }

            .smallest-title {
              font-size: 20px;
              font-family: $font-family-bold;
              margin-bottom: 0;
            }

            .bold {
              color: $white;
              font-family: $font-family-bold;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
