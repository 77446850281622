@import "../../base.scss";

.Tabs-Contact {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .section-container {
    &.howit {
      width: 95%;
    }
  }
}

.contact-component-container {
  width: 100vw;

  .right-container {
  }
  width: 100%;
}
.Full-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  height: 95vh;
  background-color: black;
  flex-direction: row;
  .Left-container {
    width: 49.5%;
    height: 100%;
    background-color: black;
    .tabs-container {
      background-color: black;
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      margin: 0;
      padding: 2vw;
      .tab-element {
        border: none;
        text-align: left;
        height: 6%;
        font-size: 24px;
        font-family: $font-family-medium;
        width: 95%;
        margin-left: 1%;
        margin-bottom: 4%;
        justify-content: flex-start;
        &.selected {
          background-color: #e5214e;
          font-family: $font-family-bold;
        }
      }
    }
  }
  .Right-container {
    padding-top: 1.5vw;
    width: 45%;
    .Questions {
      color: white;
      li::marker {
        content: none;
      }

      .questions-wrapper {
        height: 90vh;
        overflow-y: auto;
        @include scrollbarRed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        padding-right: 20px;
      }
    }
    .leftt-line {
      border: 2px solid #e5214e;
      background-color: #e5214e;
      border-radius: 5px;
      height: 3vh;
      width: 0%;
      margin-right: 2%;
    }
    .question-and-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4%;

      &.hidden {
        display: none;
      }

      .icon {
        color: #e5214e;
        &.rotated {
          transform: rotate(180deg);
          i {
            color: white;
          }
        }
      }
      .question {
        font-size: 22px;
        width: 99%;
        text-align: left;
      }

      i {
        width: 50px;
        height: 50px;
      }
    }
    .bullet-and-text {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 4%;
  
        &.hidden {
          display: none;
        }
  
        .icon {
            border: 2px solid #e5214e;
            background-color: #e5214e;
            border-radius: 5px;
            height: 3vh;
            width: 0%;
            margin-right: 2%;
            margin-top: 2px;

          &.rotated {
            transform: rotate(180deg);
            i {
              color: white;
            }
          }
        }
        .text {
          font-size: 18px;
          width: 99%;
          text-align: left;
          font-family:  $font-family-light;
        }
  
        i {
          width: 50px;
          height: 50px;
        }
    }
    .answer {
      margin-left: -3%;
      margin-top: 0.5vw;
      // margin-top:5%;

      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .Full-container {
    .Left-container {
      .tabs-container {
        .tab-element {
          font-size: 18px;
        }
      }
    }
    .Right-container .bullet-and-text .text {
        font-size: 17px;
    }
  }
}

@media screen and (max-width: 900px) {
  .Full-container {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-flow: column;
  }

  .number1 {
    background-color: #1076b5;
    color: white;
    text-align: center;
    &.moveupwards {
      margin-top: -7.1%;
    }
  }

  .question-answer-container {
    width: 100%;
    padding: 20px 0px;

    &.hidden {
      display: none;
    }
    .questions-container {
      color: white;
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      background-color: black;
      background-color: $red;
      // &.selected{
      //     background-color: #E5214E;
      // }
      &.hidden {
        display: none;
      }

      .mobile-question {
        padding: 2.5%;
        font-size: 16px;
        margin-left: 0;
        text-align: center;
        width: 100%;
      }
    }
  }

  .icon {
    color: #e5214e;

    &.rotated {
      transform: rotate(180deg);
      i {
        color: white;
      }
    }
  }
  .mobile-answer {
    color: white;
    font-size: 15px;
    word-wrap: break-word;
  }
  #mobile-Short-demo {
    color: white;
    font-size: 13px;
  }

  .inner-questions-n-answers {
    color: white;

    ul {
      list-style: none;
    }

    .line-and-text {
      margin-top: 30px;
    }
    .inner-question {
      font-family: $font-family-bold;
      font-size: 18px;
      margin-left: 5%;
      // border-left: 3px solid $red;
    }
    .inner-asnwer {
      width: 95%;
      font-size: 16px;
      margin-top: 5px;
      padding: 0;
      padding-bottom: 30px;
      border-bottom: 1px solid $red;
      font-family: $font-family-light;

      &:last-of-type {
        border-bottom: 0;
      }

      p {
        padding: 0;
        margin-left: 0 !important;
      }
    }
  }
}
