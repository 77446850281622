@import "./base.scss";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@-ms-viewport{
  width: device-width;
}

html {
  overflow-x: hidden;
  aspect-ratio: 16/9;
  // zoom: 1;
}
body {
  background-color: #13121C;
  color: $white;
  font-size: $fontSizeText;
  font-family: $font-family-regular;
  overflow-x: hidden;
}
h1 {
  margin: 0;
}

p {
  font-size: 18px;
  font-family:$font-family-light;
  line-height: 1.5em;
}

#subtitle {
  font-size: 32px;
  font-family: $font-family-medium;
}

li{
  font-size: 19px;
  font-family:$font-family-bold;
  line-height: 1.2em;
}

.flex-container {
  @extend %flex;
}

b{
  font-family: $font-family-bold;
}
.lower-title{
  font-size:22px;
}

@media screen and (max-width: 1600px) {
  p,li {
    font-size: 17px;
  }

  // html {
  //   zoom: 1;
  // }
}

@media screen and (max-width: 1200px) {
  p,li {
    font-size: 14px;
  }

  // html {
  //   zoom: 1;
  // }

  // @-ms-viewport{
  //   width: 1200px;
  // }
  
}

@media screen and (max-width: 900px) {
  body{
    overflow-x: hidden;
  }
  p {
    font-size: 16px;
    padding: 0 10px;
  }

  #upper-title{
    padding:0 5px;
    font-size:25px;
  }

  #lower-title{
    font-size:20px;
  }
 h4{
   margin-left:4%;
   margin-bottom: 5%;
 }
}

