.background-quiz {
  background-image: url("https://i.imgur.com/DAihWmW.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  z-index:999;
  background-color: #000000;
  text-align: center;
  padding-top: 5%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

a {
  color: #ffffff;
  text-decoration: none;
}
a img {
  max-width: 140px;
  margin-top: 10px;
}
.lang {
  display: inline-block;
  margin: 0 40px;
}
.splash {
  width: 100%;
  max-width: 550px;
  margin: 50px 0px 50px 0px;
}
@media screen and (max-width: 800px) {
  .lang {
    display: block;
    margin-top: 40px;
  }
  .splash {
    max-width: 80%;
    margin: 0px;
  }
  a img {
    width: 80px;
  }
}
