@import "../../base.scss";

.full-footer-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: black;
  width:100vw;
  color: $white;
  
  p {
    font-size:17px;
    font-family: $font-family-regular;
  }
  #centered {
    text-align: center;
  }
  #smaller {
    font-size: $fontSizeTextSmall;
    margin-bottom: 10px;
  }
  #smaller:hover {
    cursor: pointer;
  }
  #email{
    cursor:pointer;
    font-size:15px;
    margin-top:1%;
  }
  #phone{
    cursor:pointer;
    font-size:15px;
    margin-top:1%;
    margin-left:2%;
  }
  #adress{
    margin-top:-2%;
    font-size:15px;
  }
}

.upper-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: $white;
  width:100%;
  margin-bottom: 1%;
  a {
    text-decoration: none;
  }
 .footer-element:first-child{
   border:none;
 }

  .footer-element {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: left;
    margin-top:2%;
    border-left: solid 0.5px gray;
    height: 180px;
    &.big {
      flex: 2;
    }
    &.column{
      flex-direction: column;
      justify-content: center;
    }
    .footer-logo{
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height:180px;
      width:150px;
      margin-top:7%;
      margin-left:-5%;
    }
    .footer-inner-element{

      &.logo{
        width:100%;
      }
      &.download{
        width:100%;
      }
      
      &.follow{
        margin-bottom:45px;
        margin-top:-3%;
      }
      &.contact{
      width:100%;
      p, .regular {
        padding-left: 0;
      }
      #bigger-info{
        margin-left:10%;
        height:5%;
        &.semibold{
          font-family: $font-family-Semibold;
        }
      }
      .contact-container{
        width:100%;
        display:flex;
        flex-direction: column;
        margin-left:8%;
        #contact-info{
          font-size:16px;
          margin-left:0%;
          margin-top:1%;
        }
       
      }
      
    }
    .footer-social-media-logo{
      width:17%;
      height:20px;
      display:inline-block;
      &.facebook{
        background-image:url(../../assets/icons/facebook.svg);
        background-size:contain;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10%;
      }
      &.instagram{
        background-image:url(../../assets/icons/instagram.svg);
        background-size:contain;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-right: 2%;
      }
      &.linkedin{
        background-image:url(../../assets/icons/linkedin.svg);
        background-size:contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      &.youtube{
        background-image:url(../../assets/icons/youtube.svg);
        background-size:contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
     

      .flex-container{
        display: flex;
        flex-flow: row;
        height:190px;
        .app-store{
          background-image:url(../../assets/icons/App-Store.svg);
          background-size:contain;
          background-repeat: no-repeat;
          width:150px;
          height:80px;
         margin-right:30px;
         cursor: pointer;
        }
        .google-play{
          background-image:url(../../assets/icons/Google-Play.svg);
          background-size:contain;
          background-repeat: no-repeat;
          width:150px;
          height:60px;
          cursor: pointer;
        }
      }
    }
    .footer-inner-element-download{
      display: flex;
      width:100%;
      margin-left:7%;
      height: 50%;
      .footer-google{
        background-image:url(../../assets/icons/Google-Play.svg);
        background-size:contain;
        background-repeat: no-repeat;
        width:140px;
        height:60px;
        cursor: pointer;
        margin-left: 10%;
      }
      .footer-apple{
        background-image:url(../../assets/icons/App-Store.svg);
        background-size:contain;
        background-repeat: no-repeat;
        width:140px;
        height:60px;
        cursor: pointer;
        margin-left: 2%;
      }
    }
  }
  
  .footer-element:last-child{
    border-left:1px solid gray;
  }
}
.lower-footer-container{
  display:flex;
  border-top:1px solid white;
}
.right-flex-container {
  .qr-code {
    background-image: url(https://fightscout.s3.eu-central-1.amazonaws.com/images/qr-code.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 130px;
  }
}

.mobile-full-footer-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: black;
  color: white;
  margin-top: -1%;
  .mobile-all-logos {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .mobile-footer-social-media-logo {
    width: 30px;
    height: 30px;

    &.facebook {
      background-image: url(../../assets/icons/facebook.svg);
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    &.instagram {
      background-image: url(../../assets/icons/instagram.svg);
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    &.linkedin {
      background-image: url(../../assets/icons/linkedin.svg);
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    &.twitter {
      background-image: url(../../assets/icons/twitter.svg);
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    &.youtube {
      background-image: url(../../assets/icons/youtube.svg);
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 10px;
    }
  }

  #mobile-bigger-info {
    font-family: $font-family-Semibold;
    padding-left: 0;
    // margin-bottom: 20px;
  }
  #mobile-bigger-regular-info {
    font-family: $font-family-regular;
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 0;
  }
  #mobile-contact-info {
    padding-left: 0;
  }

  .mobile-footer-element {
    padding: 20px;
    border-bottom: 1px solid gray;
    &.last {
      border-top: 2px solid white;

      #bigger {
        padding-left: 0;
      }
    }
  }
  .mobile-footer-inner-element {
    margin-top: 50px;
    
    p {
      font-size: 18px;
      font-family: $font-family-regular;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-left: 0;
    }

    .regular {
      font-family: $font-family-regular;
      margin-bottom: 15px;
      padding-left: 0;
    }
  }

  .mobile-footer-logo {
    background-image: url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Fight-Scout-logo-footer.png");
    background-position: left;
    background-size: 70%;
    background-repeat: no-repeat;
    height: 200px;
    padding-bottom: 20%;
    width: 40%;
    margin-left: 6%;
  }
  .mobile-flex-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    .mobile-app-store {
      background-image: url(../../assets/icons/App-Store.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 140px;
      width: 100%;
      height: 80px;
      width: 100%;
      // margin-right: 30px;
      // margin-left: 2%;
      cursor: pointer;
    }
    .mobile-google-play {
      background-image: url(../../assets/icons/Google-Play.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 140px;
      width: 100%;
      height: 80px;
      cursor: pointer;
      // margin-left: 2%;
    }
  }
}

.outer-mobile-flex-container {
  display: flex;
  flex-direction: column;
  .mobile-qr {
    background-image: url(https://fightscout.s3.eu-central-1.amazonaws.com/images/qr-code.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
    margin-left: 2%;
    display: none;
  }
}
