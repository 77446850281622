@import "../../base.scss";

//texts
#firstHome {
  font-size: 42px;
  font-family: $font-family-bold;
}
.first-section-inner-header {
  font-size: 29px;
  font-family: $font-family-bold;
  padding: 0;
  margin: 0.5% 0% 2% 0%;
}
.bold-tab-text {
  padding: 0 12px;
  border-radius: 4px;
  font-family: $font-family-bold;
  font-size: 22px;
}
.not-bold-tab-text {
  padding: 0 12px;
  //border-left:7px solid turquoise;
  border-radius: 4px;
  font-family: $font-family-light;
  font-size: 20px;
}

.medium-tab-text {
  padding: 0 14px;
  font-family: $font-family-medium;
  font-size: 21px;
}

p {
  font-family: $font-family-light;
}

.button-container {
  &.middle {
    position: absolute;
    left: 42%;
  }
}

#textforad {
  width: 40%;
  margin: 0 auto;
  margin-top: 1%;
  text-align: center;
  color: white;
  font-size: 42px;
}

.logoforad {
  &.mobile {
    width: 70%;
    background-position: center;
  }
  margin: 0 auto;
  margin-top: 15%;
  //background-image: url('../../assets/images/logo-white-color.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 30%;
  height: 20%;
}

.ad {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //background-image:url('../../assets/images/homepage-start-now-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  .smaller-line {
    width: 90%;
    height: 90%;
    position: absolute;
    border: 2px solid #0bd8de;
  }
}

.home-container {
  width: 100vw;
  overflow-x: hidden;
  .button-container {
    &.middle {
      margin-top: 3%;
    }
  }
  #subtitle {
    font-size: 32px;
    font-family: $font-family-medium;
  }

  .container-inner {
    width: 100%;
    .home-line-and-tabs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: left;
      width: 100%;
      flex-wrap: wrap;
      .home-line-and-text {
        display: flex;
        align-items: center;
        margin-bottom: 2%;
        width: 33%;
        &.bigger {
          width: 50%;
          margin-left: -1%;
        }
        .leftt-line {
          border: 2px solid #e5214e;
          background-color: #E5214E;
          height: 4vh;
          margin-left: 5%;
        }
        .tab-text {
          font-family: $font-family-bold;
          &.smaller {
            font-size: 13px;
          }
        }
        p {
          padding: 0 9px;
        }
      }
    }
    .homepage-buttons {
      display: flex;
      .button-google {
        background-image: url("../../assets/icons/Google-Play.svg");
        height: 70px;
        width: 150px;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10%;
      }
      .button-app {
        background-image: url("../../assets/icons/App-Store.svg");
        height: 70px;
        width: 150px;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.wide {
        width: 100%;
      }
      &.column {
        flex-direction: column;
      }
      &.start {
        align-items: flex-start;
      }
      &.start-row {
        justify-content: flex-start;
      }

      .title {
        font-family: $font-family-light;
        font-size: 20px;
        text-transform: uppercase;
        white-space: nowrap;
        // @apply w-full flex items-center; doesn't work -> tailwind setup problem
      }
    }
    .cards-wrapper-home {
      gap: 15px;
      align-items: stretch;
      z-index: 1000;

      .card-wrapper {
        border: 1px solid rgba(255, 255, 255, 0.753);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 30px 20px;
        min-height: 100%;
        background-color: transparent;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        flex: 1;

        .transparent {
          background-color: #000;
          opacity: 0.7;
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
          min-width: 100%;
          min-height: 100%;
          border-radius: 8px;
        }
        
        .date {
          font-size: 14px;
          font-family: $font-family-light;
          z-index: 1;
        }
        .text {
          font-size: 16px;
          font-family: $font-family-regular;
          z-index: 1;

          &.preview {
            display: -webkit-box;
            overflow : hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin-left: 0;
        }
        }
        .underline {
          text-decoration: underline;
          text-transform: uppercase;
          cursor: pointer;
        }
        .start {
          align-self: flex-start;
        }
        .end {
          align-self: flex-end;
          margin-top: auto;
        }
        .red {
          color: #E5214E;
        }
      }
    }
  }
  .section-container {
    width: 100%;
    &.mobile {
      width: 100%;
    }
  }
  .button-n-text {
    .vertical-tab-buttons {
      .tab-text {
        padding: 0 20px;
        border-left: 7px solid turquoise;
        border-radius: 4px;
        font-family: $font-family-bold;
      }
      .line-and-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20%;
        margin-top: 1%;

        .left-line {
          border: 4px solid turquoise;
          border-radius: 5px;
          height: 6vh;
        }
      }
      .line-and-text:last-child {
        &.firstSection {
          margin-top: 1%;
        }
        margin-top: 3%;
      }
    }
  }
  .Tabs {
    display: flex;
    flex-direction: row;
  }
  .vertical-tab-buttons {
    &.no-border {
      border-left: none;
    }
  }

  .map {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: inline-block;
    position: absolute;
  }

  .circle {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
  }

  .circle-link {
    position: absolute;
    display: inline-block;
    // width: 25%;
    // height: 25%;
    width: calc(10vh + 6%);
    height: calc(10vh + 6%);

    //new
    width: calc(11vh + 11%);
    height: calc(11vh + 11%);

    width: 19%;
    height: 25%;
    // background-color: pink;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      transform: scale(110%);
      transition: 0.5s;
    }
  }
  
  // #circle-link-1 {
  //   top: 18%;
  //   right: 43%;
  // }
  
  //new
  #circle-link-1 {
    top: 0;
    right: 40%;
  }
  
  #circle-link-2 {
    top: 30%;
    right: 0;
  }
  
  #circle-link-3 {
    top: 30%;
    left: 0;
  }
  
  #circle-link-4 {
    bottom: 5%;
    left: 7%;
  }
  
  #circle-link-5 {
    bottom: 5%;
    right: 7%;
  }


}

.inner-mobile-tabs {
  width: 100%;
  height: 100vh;
  color: white;
}

.image-background {
  // background-image: url("../../assets/images/homepage-section-4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 70%;
}
.lower-inner-mobile {
  margin-top: 5%;
  height: 40vh;
}

.second-line {
  display: flex;
  justify-content: space-between;
  .homee-line-and-text {
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    width: 30%;
    white-space: nowrap;
    .leftt-line {
      border: 2px solid #e5214e;
      background-color: #E5214E;
      height: 4vh;
      margin-left: 5%;
    }
    .tab-text {
      font-family: $font-family-bold;
      &.smaller {
        font-size: 13px;
      }
    }
    p {
      padding: 0 9px;
    }
  }
}

// 1149 x 802

@media screen and (min-width: 2500px) {
  .home-container {
    .container-inner {
      .cards-wrapper-home {
        .card-wrapper {
          height: 30vh;
        }
      }
    }
    .section-container {
  
      &.slider {
        .cards-wrapper .card-wrapper {
          height: 45vh;
          width: unset;
        }

        .slick-dots {
          margin-left: 26px;
        }
      }
    }

    .circle-link {
      width: calc(11vh + 6%);
      height: calc(13vh + 6%);
    }

    #circle-link-1 {
      right: 43%;
      top: 1%;
    }

    #circle-link-2 {
      // top: 41%;
      right: 2%;
    }

    #circle-link-3 {
      top: 41%;
      left: 2%;
    }
    
    #circle-link-4 {
      bottom: 11%;
      left: 11%;
    }
    
    #circle-link-5 {
      bottom: 11%;
      right: 11%;
    }
  }
}

@media screen and (min-width: 2100px) {
  .home-container {
    #circle-link-1 {
      right: 43%;
      top: 3%;
    }
    #circle-link-2 {
      right: 2%;
      top: 33%
    }
    
    #circle-link-3 {
      top: 33%;
      left: 2%;
    }
    
    #circle-link-4 {
      bottom: 7%;
      left: 11%;
    }
    
    #circle-link-5 {
      bottom: 7%;
      right: 11%;
    }
  }
}

@media screen and (min-width: 3100px) {
  .home-container {

    #circle-link-2 {
      right: 9%;
    }

    #circle-link-3 {
      left: 9%;
    }
    
    #circle-link-4 {
      left: 16%;
    }
    
    #circle-link-5 {
      right: 16%;
    }
  }
}

@media screen and (max-width: 1800px) {
  .button-container {
    &.middle {
      position: absolute;
      left: 40%;
    }
  }
}

@media screen and (max-width: 1600px) {
  #firstHome {
    font-size: 34px;
    font-family: $font-family-bold;
  }

  .first-section-inner-header {
    font-size: 24px;
  }

  .bold-tab-text {
    font-family: $font-family-bold;
    font-size: 17px;
  }

  .not-bold-tab-text {
    font-family: $font-family-light;
    font-size: 15px;
  }

  #textforad {
    font-size: 32px;
  }

  .medium-tab-text {
    padding: 0 14px;
    font-family: $font-family-medium;
    font-size: 17px;
  }

  .home-container {
    // #circle-link-1 {
    //   right: 43%;
    //   top: calc(10vh + 9%);
    // }
    
    // #circle-link-2 {
    //   top: 40%;
    //   right: 2%;
    // }
    
    // #circle-link-3 {
    //   top: 40%;
    //   left: 2%;
    // }
    
    // #circle-link-4 {
    //   bottom: 11%;
    //   left: 10%;
    // }
    
    // #circle-link-5 {
    //   bottom: 11%;
    //   right: 10%;
    // }

    .section-container {
      // height: auto;
      width: 100%;
  
      &.slider {
        .container-content {
          .container-inner {
            width: 100%
          }
        }
        // .cards-wrapper {
        //   // width: 92%;
        //   // margin: 0 3.5%;
        // }
        .cards-wrapper .card-wrapper {
          height: 96vh;
        }
      }
      .container.video {
        height: fit-content;
        padding-top: 30px;
      }
    }
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .container-inner {
    .title-container {
      margin-top: 5% !important;
    }
  }

  // .home-container {
  //   #circle-link-1 {
  //     right: 43.5%;
  //     top: calc(15vh + 12%);
  //   }
    
  //   #circle-link-2 {
  //     top: 45%;
  //     right: 3%;
  //   }
    
  //   #circle-link-3 {
  //     top: 45%;
  //     left: 3%;
  //   }
    
  //   #circle-link-4 {
  //     bottom: 13%;
  //     left: 11%;
  //   }
    
  //   #circle-link-5 {
  //     bottom: 13%;
  //     right: 11%;
  //   }
  // }
}

@media screen and (max-width: 1440px) {
  .logoforad {
    margin-top: 25%;
    height: 15%;
  }

  .home-container {
    // #circle-link-1 {
    //   right: 43%;
    //   top: calc(11vh + 11%);
    // }
    
    // #circle-link-2 {
    //   top: 42%;
    //   right: 2%;
    // }
    
    // #circle-link-3 {
    //   top: 42%;
    //   left: 2%;
    // }
    
    // #circle-link-4 {
    //   bottom: 13%;
    //   left: 10%;
    // }
    
    // #circle-link-5 {
    //   bottom: 13%;
    //   right: 10%;
    // }

    .container-inner {
      .cards-wrapper-home .card-wrapper {
        height: 43vh;
        overflow-y: hidden;
        overflow-x: hidden;

        .text {
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          // height: 190px;

          &.end {
            margin-top: auto;
            height: fit-content;
          }
        }
      }
    }

    .section-container {
      height: auto;
  
      &.slider {
        .cards-wrapper .card-wrapper {
          height: 91vh;

          .top {
            margin: 0;
          }
        }
      }
      .container.video {
        // height: fit-content;
        height: 100%;
        padding-top: 30px;
      }
    }
  
    // .container.video {
    //   height: fit-content;
    // }
  }
}


// zoom test
// @media screen and (min-zoom: 110%) and (max-zoom: 200%) {
//   .logoforad {
//     margin-top: 25%;
//     height: 15%;
//   }

//   .home-container {
//     #circle-link-1 {
//       right: 43%;
//       top: calc(11vh + 11%);
//     }
    
//     #circle-link-2 {
//       top: 42%;
//       right: 2%;
//     }
    
//     #circle-link-3 {
//       top: 42%;
//       left: 2%;
//     }
    
//     #circle-link-4 {
//       bottom: 13%;
//       left: 10%;
//     }
    
//     #circle-link-5 {
//       bottom: 13%;
//       right: 10%;
//     }

//     .container-inner {
//       .cards-wrapper-home .card-wrapper {
//         height: 43vh;
//         overflow-y: hidden;
//         overflow-x: hidden;

//         .text {
//           text-overflow: ellipsis;
//           overflow: hidden;
//           display: block;
//           height: 190px;

//           &.end {
//             margin-top: auto;
//             height: fit-content;
//           }
//         }
//       }
//     }

//     .section-container {
//       height: auto;
  
//       &.slider {
//         .cards-wrapper .card-wrapper {
//           height: 91vh;

//           .top {
//             margin: 0;
//           }
//         }
//       }
//       .container.video {
//         height: fit-content;
//         padding-top: 30px;
//       }
//     }
  
//     .container.video {
//       height: fit-content;
//     }
//   }
// }

// test zoom
// @media screen and (max-width: 1440px) and (zoom: 1.5) {
//   .logoforad {
//     margin-top: 25%;
//     height: 15%;
//   }

//   .home-container {
//     #circle-link-1 {
//       right: 43%;
//       top: calc(11vh + 11%);
//     }
    
//     #circle-link-2 {
//       top: 42%;
//       right: 2%;
//     }
    
//     #circle-link-3 {
//       top: 42%;
//       left: 2%;
//     }
    
//     #circle-link-4 {
//       bottom: 13%;
//       left: 10%;
//     }
    
//     #circle-link-5 {
//       bottom: 13%;
//       right: 10%;
//     }

//     .container-inner {
//       .cards-wrapper-home .card-wrapper {
//         height: 43vh;
//         overflow-y: hidden;
//         overflow-x: hidden;

//         .text {
//           text-overflow: ellipsis;
//           overflow: hidden;
//           display: block;
//           height: 190px;

//           &.end {
//             margin-top: auto;
//             height: fit-content;
//           }
//         }
//       }
//     }

//     .section-container {
//       height: auto;
  
//       &.slider {
//         .cards-wrapper .card-wrapper {
//           height: 91vh;

//           .top {
//             margin: 0;
//           }
//         }
//       }
//       .container.video {
//         height: fit-content;
//         padding-top: 30px;
//       }
//     }
  
//     .container.video {
//       height: fit-content;
//     }
//   }
// }

@media screen and (max-width: 1200px) {
  #firstHome {
    font-size: 28px;
    font-family: $font-family-bold;
  }
  .first-section-inner-header {
    font-size: 22px;
  }

  .bold-tab-text {
    font-family: $font-family-bold;
    font-size: 14px;
  }
  .not-bold-tab-text {
    font-family: $font-family-light;
    font-size: 13px;
  }
  #textforad {
    font-size: 26px;
  }
  .medium-tab-text {
    padding: 0 14px;
    font-family: $font-family-medium;
    font-size: 14px;
  }

  .logoforad {
    height: 15%;
    margin-top: 30%;
  }

  .home-container {
    overflow-x: hidden;
    // .container-inner {
    //   .cards-wrapper-home {
    //     flex-direction: column;
  
    //     .card-wrapper {
    //       height: 8vh;

    //       .date {
    //         margin-bottom: 1em !important;
    //       }
    //       .text {
    //         margin-bottom: 2em !important;
    //         height: fit-content;

    //         &.end {
    //           margin-bottom: 0.2em !important;
    //         }
    //       }
    //     }
    //   }
    // }

    #circle-link-1 {
      right: 43%;
      top: 2%;
    }

    .container-inner {
      .flex-wrapper {
        margin-bottom: 15px !important;
      }

      // .cards-wrapper-home {
      //   overflow-y: scroll;
      //   height: 35vh;
      // }
    }

    .section-container {
      height: auto;
  
      &.slider {
        .cards-wrapper .card-wrapper {
          height: 99vh;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (max-height: 900px) {
  .home-container {
    .circle-link {
      width: calc(14vh + 6%);
      height: calc(14vh + 6%);
    }
  }
}

// @media screen and (max-width: 1200px) and (max-height: 768px) {
//   .home-container {
//     .circle-link {
//       width: calc(11vh + 6%);
//       height: calc(11vh + 6%);
//     }
//   }
// }

// zoomed
// @media screen and (min-width: 900px) and (max-width: 1500px) and (max-height: 1400px) {
//   .home-container {
//     .circle-link {
//       width: calc(16vh + 8%);
//       height: calc(16vh + 8%);
//     }
//   }
// }

@media screen and (max-width: 1100px) {
  .home-container {
    .section-container {
  
      &.slider {
        .cards-wrapper .card-wrapper {
          padding-top: 20px;
          height: 60vh;

          .top {
            margin-top: 0;

            img {
              margin-top: 0;
            }

            .smaller-title {
              font-size: 20px;
            }
          }
        }
      }
    }

    .container-inner {
      .cards-wrapper-home {
        flex-direction: column;
        .card-wrapper {
          height: 30vh;

          // .text {
          //   height: 100px;
          // }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .home-container {
    .container-inner {
      .cards-wrapper-home {
        overflow-y: auto;
        height: 32vh;
        @include scrollbarBlack;
      }
    }
  }
}

// zoomed
@media screen and (min-width: 900px) and (max-height: 900px) {
  .home-container {

    .container-inner {
      .cards-wrapper-home {
        overflow-y: auto;
        height: 45vh;
        @include scrollbarBlack;

        .card-wrapper {
          height: 22vh;
          .date.start {
            margin-bottom: 1.2em;
          }
          .text {
            margin-bottom: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .section-container {
    margin-bottom: 15%;
  }
  .button-container {
    &.right {
      margin-left: 18%;
    }
  }
  #firstHome {
    margin-top: 2%;
    font-size: 22px;
    font-family: $font-family-bold;
  }
  .first-section-inner-header {
    font-size: 20px;
  }

  #firstfromwhole {
    margin-top: -5%;
  }

  .vertical-tab-buttons {
    margin-top: -2%;
    &.no-border {
      border-left: none;
    }
  }

  .first-section-text {
    margin-top: -15%;
  }

  .move-left {
    margin-left: 2%;
  }

  #abc {
    padding: 3%;
  }
  .button-n-text {
    padding: 3%;
    margin-top: -2%;
    &.bigger {
      height: 150vh;
    }
  }
  .ad {
    height: 80vh;
  }
  #textforad {
    font-size: 17px;
    width: 90%;
  }
  .logoforad {
    margin-top: 55%;
  }
  .mobile-address {
    margin-top: -5%;
  }
  b {
    &.mobile {
      color: #0bd8de;
    }
  }
  .home-container {
    overflow-x: hidden;
  }
  .home-container {
    .number1,
    .number2,
    .number3 {
      height: 20%;
      background-color: #1076b5;
      color: white;
      text-align: center;
    }
    .whole-section {
      &.black {
        background-color: black;
        color: white;
      }
    }
    .mobile-tabs-container {
      &.blended {
        background: linear-gradient(135deg, #b12045, #050508 95%);
      }
    }

    .container-inner {
      .cards-wrapper-home .card-wrapper {
        height: 35vh;
      }
    }
  }

  .section-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;

    .container {
      &.image {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 200px;
      }
      &.content {
        // padding-left: 10px;
        // margin-left: -5%;
        .container-inner {
          width: 90%;
          #abc {
            font-size: 24px;
          }

          .bold-tab-text {
            font-size: 13px;
          }
        }
      }
    }
    .left-line-mobile {
      border: 4px solid turquoise;
      border-radius: 5px;
      height: 3vh;
    }
  }
  .container-image {
    width: 100vw;
  }
  .container-content {
    width: 100vw;
  }
  .section-container.inverted {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;

    .title-container {
      color: white;
      h1 {
        font-size: 25px;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    .button-google {
      background-image: url("../../assets/icons/Google-Play.svg");
      height: 70px;
      width: 150px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-left: 1%;
      cursor: pointer;
    }
    .button-app {
      background-image: url("../../assets/icons/App-Store.svg");
      height: 70px;
      width: 150px;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .home-container {

    .section-container,
    .image-section-container {
      flex-direction: column;
      align-items: center;
      height: fit-content !important;

      .container.content,
      .container.center {
        .container-inner {
          flex-direction: column;
          height: fit-content;

          .title-container {
            width: 100%;
            h1 {
              font-size: 22px;
              margin-left: 0;
            text-align: left;

            }
          }

          .line-and-text {
            text-align: left;
            
            p {
              font-size: 16px;
              padding: 0;
            }
            .lower-title {
              font-size: 18px;
            }
          }

          .cards-wrapper-home {
            flex-direction: column;
            // width: 100%;
          }
        }
      }
    }
    .video-container {
      height: 25vh;
    }

    .container-inner .cards-wrapper-home .card-wrapper {

      .text {
        &.end.mobile-text {
          font-size: 14px;
          font-family: $font-family-light;
        }
      }

      .date.start {
        margin-bottom: 23%;
      }
    }

    .section-container.slider .container-inner.mobile .cards-wrapper.mobile .card-wrapper .subtitle, .section-container.slider .container-inner .cards-wrapper.mobile .card-wrapper .subtitle {
      text-align: center;
      // margin-bottom: 10px;
    }

    // .section-container.slider .container-inner.mobile .cards-wrapper.mobile .card-wrapper, .section-container.slider .container-inner .cards-wrapper.mobile .card-wrapper {
    //   // height: 50vh;
    // }
  }
}

.home-mobile-line-and-text {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  width: 90%;
  &.bigger {
    width: 95%;
    margin-left: -1%;
  }
  .leftt-line {
    border: 2px solid #e5214e;
    background-color: #E5214E;
    height: 4vh;
    margin-left: 5%;
  }
  .tab-text {
    font-family: $font-family-bold;
    &.smaller {
      font-size: 13px;
    }
  }
  p {
    padding: 0 12px;
  }
}
